@import 'styles.scss';

.home-section-dashboard,
.home-section-events,
.ticketer-section-content,
.events-section-content,
.ticketer-sections-section,
.home-section-more,
.delete-event,
.detail-event-info,
.detail-event-cards,
.detail-event-metrics,
.detail-event-discounts,
.detail-event-delete,
.home-elvis-main-data,
.landing-event-body,
.landing-main section,
.my-tickets-content,
.create-tickets-bottom,
.details-ticket-section {
  max-width: 1440px;
}

#root {
  overflow-y: auto;
}

.fetching-error {
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $red-error;
  text-align: center;
}

//CKEDITOR

:root {
  --ck-border-radius: 8px;
}

.ck.ck-editor__editable > .ck-placeholder::before {
  color: $grey-upload-image;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
}

.ck-editor__editable_inline {
  min-height: 212px;
}

//SIDEBAR AND FOOTER

.dashboard-main {
  margin: 0rem;
  padding: 0rem;
  width: 100%;
  min-width: fit-content;
  height: max-content;
  min-height: calc(100vh - 0px);
  background: rgba($grey-mail-back, 0.05);
  overflow-y: auto;
}

.dashboard-pages {
  margin-left: 294px;
  width: 100%;
  //position: absolute;
  //left: 294px;
  //background: rgba($grey-mail-back, 0.05);
}

.sidebar-section {
  position: fixed;
  margin: 0rem;
  padding: $dimenXXL $dimenM $dimenXXL $dimenL;
  width: 294px;
  min-height: 100vh;
  background: $white-2;
  box-shadow: 0px 4px 50px rgba(204, 204, 204, 0.1);
  z-index: 2;
}

.sidebar-section-top {
  gap: $dimenM;
  h2 {
    margin: 0rem;
    color: $green-3;
    font-size: 40px;
    font-family: $font4;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  img {
    margin: 0px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.sidebar-item {
  align-items: center;
  margin: 0rem;
  padding: $dimenXS $dimenS;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
  text-decoration: none;
  border: 0px;
  border-radius: 10px;
  gap: $dimenXS;
}

.sidebar-item > svg path {
  fill: $grey-blue;
}

.sidebar-item-active {
  color: $violet-dark;
  background: $purple;
}

.sidebar-item-active > svg path {
  fill: $violet-dark;
}

.footer-section {
  z-index: 5;
  position: fixed;
  bottom: 0px;
  margin: 0rem;
  padding: $dimenXS $dimenL;
  width: 100vw;
  height: 86px;
  background: $white-2;
  box-shadow: 0px 0px 10px rgba(174, 174, 174, 0.2);
}

.footer-item {
  margin: 0rem;
  padding: $dimenXXS 0rem;
  height: 100%;
  width: 18%;
  min-width: 52px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 120%;
  font-feature-settings: 'calt' off;
  color: $grey-blue;
  text-decoration: none;
  border: 0px;
  border-radius: 16px;
  gap: $dimenXXXS;
}

.footer-item > svg,
.footer-item > div {
  margin: 0rem;
  height: 28px;
  width: 28px;
}

.footer-item-active {
  color: $violet-dark;
  background: $purple;
}

.footer-item-active:hover {
  color: $violet-dark !important;
}

.footer-item-active > svg path {
  fill: $violet-dark;
}

@media (max-width: $mediaLG) {
  .dashboard-pages {
    margin-left: 0px;
    //position: static;
    //left: 0px;
  }
}

//PROFILE

.profile-section,
.home-section,
.ticketer-section,
.events-section,
.ticketer-sections-section,
.my-tickets-section,
.create-tickets-event-section {
  margin: 0rem;
  padding: $dimenXXL $dimenXXXXL;
  width: 100%;
  gap: $dimenXXS;
}

.bar-section-buttons {
  margin: 0rem;
  padding: 0rem;
  width: 100%;
  height: 48px;
}

.alt-buttons {
  button {
    background: $violet;
    color: $purple-text;
    path {
      fill: $purple-text;
    }
  }
}

.bar-section-buttons-left {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -1px;
  color: $black-1;
  gap: $dimenXXS;
  span {
    margin: 0rem;
    font-weight: 400;
  }
}

.crazy::after {
  content: '😜';
}

.greeting::after {
  content: '👋';
}

.ticketer::after {
  content: '💘';
}

.events::after {
  content: '🌟';
}

.create-event::after {
  content: '💫';
}

.bar-section-buttons-right {
  gap: $dimenM;
}

.bar-section-buttons-right h6,
.profile-section-bottom h6 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  text-decoration-line: underline;
  font-feature-settings: 'calt' off;
  color: $purple-dark;
  cursor: pointer;
}

.profile-section > p {
  margin: 0rem;
  margin-bottom: $dimenL;
  width: 100%;
  font-family: $font2;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: $grey-dark;
}

.profile-section-box {
  position: relative;
  padding: calc(110px + $dimenXXXL) $dimenXXXL $dimenXXXL $dimenXXXL;
  width: 100%;
  height: 100%;
  background: $white;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 16px;
}

.profile-section-box-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 113px;
  background: linear-gradient(247.42deg, #ebe8ff -3.75%, #432ec9 93.63%);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  z-index: 1;
}

.profile-section-box > svg:nth-child(1) {
  position: absolute;
  top: 0px;
  left: 40px;
  z-index: 2;
}

.profile-section-box > svg:nth-child(2) {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.profile-section-box-data {
  margin: 0rem;
  gap: $dimenXS;
}

.profile-section-box h4 {
  margin: 0rem;
  margin-bottom: $dimenXL;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $black-1;
}

.profile-section-box h4:last-child {
  margin-bottom: 0rem;
}

.profile-section-box h5 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $grey-navbar;
}

.profile-section-picture {
  position: absolute;
  top: 26px;
  left: 54px;
  margin: 0em;
  padding: $dimenXXXS;
  width: 174px;
  height: 174px;
  border: 0px;
  border-radius: 50%;
  background: linear-gradient(252.02deg, #432ec9 10.76%, #ebe8ff 110.29%);
  z-index: 2;
  img {
    cursor: pointer;
  }
  img:hover {
    opacity: 0.8;
  }
  img:active {
    opacity: 0.6;
  }
}

.profile-section-picture-inside-initials {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 57.6326px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $purple-text;
  text-transform: uppercase;
}

.profile-section-picture .profile-section-picture-inside-hover {
  display: none;
  margin: 0rem;
  padding: $dimenS;
  height: 166px;
  width: 166px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: $white;
  background: rgba(23, 10, 99, 0.8);
  border: 0px;
  border-radius: 50%;
}

.profile-section-picture:hover .profile-section-picture-inside-hover {
  display: flex;
  position: absolute;
}

.profile-section-picture-inside,
.profile-section-picture img {
  margin: 0em;
  padding: 0em;
  width: 100%;
  height: 100%;
  border: 0px;
  border-radius: 50%;
  z-index: 3;
  background: $violet-dark;
}

.profile-section-picture-inside input {
  position: absolute;
  opacity: 0;
  margin: 0rem;
  height: 166px;
  width: 166px;
  border: 0px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-section-picture-edit {
  position: absolute;
  bottom: 6px;
  right: 6px;
  margin: 0em;
  width: 37px;
  height: 37px;
  border: 0px;
  border-radius: 50%;
  background: $purple;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  z-index: 4;
}

.profile-section-picture-edit {
  bottom: 6px;
  right: 6px;
}

.profile-section-picture-edit svg {
  margin: 0em;
  width: 23px;
  height: 23px;
}

.profile-section-picture-delete {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 4;
  cursor: pointer;
  svg {
    margin: 0em;
    width: 24px;
    height: 24px;
  }
}

.profile-section-picture-delete:hover {
  opacity: 0.8;
}

.profile-section-picture-delete:active {
  opacity: 0.6;
}

.change-user-data {
  width: 100%;
}

.profile-section-box-padding {
  padding-left: 15rem;
}

.profile-upload-image-mobile {
  position: fixed; /* Stay in place */
  z-index: 6; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($black-1, 0.5);
}

.profile-upload-image-mobile-box {
  position: relative;
  margin: 0rem;
  padding: 2.5rem 3.5rem;
  width: 500px;
  background: $white;
  box-shadow: 0px 0px 10px rgba(174, 174, 174, 0.2);
  border-radius: 14px;
  gap: $dimenL;
}

@media (max-width: $mediaMD) {
  .profile-upload-image-mobile-box {
    width: 360px;
    padding: 1.5rem 2.5rem;
  }
}

.profile-upload-image-mobile-box-line {
  position: absolute;
  top: 8px;
  left: calc(50% - 11px);
  width: 22px;
  height: 4px;
  background: $grey-upload-image-line;
  border: 0px;
  border-radius: 2px;
}

.profile-upload-image-mobile-box p {
  margin: 0rem;
  margin-bottom: $dimenXXS;
  width: calc(100% - $dimenXXXL);
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: $grey-upload-image;
}

.profile-upload-image-mobile-box label,
.profile-upload-image-mobile-box button {
  margin: 0rem;
  height: 44px;
  width: 100%;
  max-width: 343px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  border: 1px dashed $grey-blue;
  border-radius: 10px;
  gap: $dimenXXS;
}

.profile-upload-image-mobile-box label:hover,
.profile-upload-image-mobile-box label:active {
  border: 0px;
  background: $purple-light-2;
  color: $purple-dark;
}

.profile-upload-image-mobile-box label:hover svg path,
.profile-upload-image-mobile-box label:active svg path {
  fill: $purple-dark;
}

.profile-upload-image-mobile-box input {
  display: none;
}

.profile-upload-image-mobile-box button {
  border: 0px;
  background: $red-light;
  color: $red-error;
}

@media (max-width: $mediaLG) {
  .dashboard-main {
    margin-bottom: 86px;
    min-width: auto;
    min-height: calc(100vh - 86px);
    overflow-y: auto;
  }

  .profile-section,
  .home-section,
  .ticketer-section,
  .events-section,
  .my-tickets-section,
  .create-tickets-event-section {
    padding: $dimenS;
    gap: $dimenM;
  }

  .profile-section-box {
    padding: calc(72px + $dimenXXXL) $dimenM $dimenXL $dimenM;
    height: 100%;
    min-height: auto;
  }

  .bar-section-buttons {
    margin-bottom: $dimenXS;
  }

  .bar-section-buttons-left::after {
    display: none;
  }

  .profile-section-box-background {
    height: 57px;
  }

  .profile-section-box > svg:nth-child(1) {
    left: 0px;
    height: 40px;
  }

  .profile-section-box > svg:nth-child(2) {
    height: 68px;
    width: 100px;
    overflow: hidden;
  }

  .profile-section-box-data {
    width: 100%;
    gap: $dimenXXS;
  }

  .profile-section-box h4 {
    margin-bottom: $dimenS;
  }

  .profile-section-bottom {
    width: 100%;
  }

  .profile-section-bottom h6 {
    font-size: 14px;
  }

  .profile-section-bottom p {
    margin: 0px;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    color: $grey-navbar;
  }

  .profile-section-picture {
    top: 7px;
    left: 29px;
    width: 84px;
    height: 84px;
  }

  .profile-section-picture .profile-section-picture-inside-hover {
    display: none;
  }

  .profile-section-picture:hover .profile-section-picture-inside-hover {
    display: none;
  }

  .profile-section-picture-inside-initials {
    font-size: 27.8226px;
    line-height: 120%;
  }

  .profile-section-picture-edit {
    bottom: 0px;
    right: 0px;
    width: 25px;
    height: 25px;
  }

  .profile-section-picture-edit svg {
    width: 14px;
    height: 14px;
  }
}

// HOME
.home-section,
.ticketer-section,
.events-section,
.my-tickets-section,
.create-tickets-event-section {
  padding: $dimenXXL $dimenXL;
  gap: $dimenL;
}

.home-section-dashboard {
  width: 100%;
  gap: $dimenS;
}

.home-section-dashboard-box {
  width: 100%;
  min-width: 304px;
  padding: $dimenL $dimenS;
  gap: $dimenXS;
  background: $white-2;
  border: 0.75px solid rgba(160, 160, 160, 0.1);
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 18px;
  flex: 1 0 244px;
}

.home-section-dashboard-icon svg {
  flex-shrink: 0;
}

.home-section-dashboard-box p,
.home-section-dashboard-box p span {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: $black-1;
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-section-dashboard-box h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 30px;
  color: #231d4f;
}

.home-section-dashboard-box h3 span {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: $grey-upload-image;
}

.home-section-events,
.home-section-events-title {
  width: 100%;
  gap: $dimenL;
}

.home-section-events-title h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: $black-1;
}

.home-section-events-title-right {
  margin: 0rem;
  padding: 0rem;
  height: 32px;
  cursor: pointer;
}

.home-section-events-title-right div {
  margin: 0rem;
  padding: 0rem;
  width: 32px;
  height: 32px;
  background: $white-2;
  border: 0.75px solid $grey-light-2;
}

.home-section-events-title-right div:nth-child(1) {
  border-radius: 10px 0px 0px 10px;
}

.home-section-events-title-right div:nth-child(2) {
  border-radius: 0px 10px 10px 0px;
}

.home-section-events-title-right div:nth-child(1) svg {
  transform: rotate(90deg);
}

.home-section-events-title-right h4 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: $purple-dark;
}

.home-section-events-title-right > svg {
  transform: rotate(270deg);
}

.home-section-events-title-right > svg path {
  fill: $purple-dark;
}

.vertical-active path {
  fill: $grey-dark;
}

.home-section-events-box {
  margin: 0rem;
  //padding: $dimenXXL;
  width: 100%;
  background: $white-2;
  box-shadow: 0px 4px 50px rgba(204, 204, 204, 0.1);
  border-radius: 12px;
}

.home-section-events-box-empty {
  margin: $dimenXXL;
}

.home-section-events-box-empty-icon {
  gap: $dimenXL;
}

.home-section-events-box-empty-text {
  margin-bottom: $dimenXL;
  gap: $dimenXXS;
}

.home-section-events-box-empty-text h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-align: center;
  color: $grey-dark;
}

.home-section-events-box-empty-text h4 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: $purple-dark;
}

.home-section-events-box-empty-text p {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: $grey-dark;
}

.home-section-events-box-empty-text p span {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: $purple-dark;
}

.home-section-dashboard-icon-select,
.event-detail-select {
  position: relative;
  margin: 0rem;
  padding: $dimenXXXS $dimenXXXS $dimenXXXS $dimenXS;
  min-width: 135px;
  height: 33px;
  background: $purple-light-2;
  border: 0.55px solid $purple-dark;
  border-radius: 10px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'calt' off;
  color: $purple-dark;
  white-space: nowrap;
  flex-shrink: 2;
}

.home-section-dashboard-icon-select-selected,
.event-detail-select-selected {
  border: 0px;
  background: $purple-dark;
  color: $purple-light;
}

.home-section-dashboard-icon-select svg,
.event-detail-select svg {
  cursor: pointer;
}

.chevron-rotated {
  transform: rotate(180deg) !important;
}

.chevron-rotated-0 {
  transform: rotate(0deg) !important;
  transition: 300ms linear;
}

.home-section-dashboard-icon-select-selected svg path,
.event-detail-select-selected svg path {
  fill: $purple-light;
}

.home-section-dashboard-icon-select ul,
.event-detail-select ul {
  position: absolute;
  top: 42px;
  right: 0px;
  margin: 0rem;
  padding: $dimenXXS;
  background: $white;
  border-radius: 10px;
  list-style-type: none;
  z-index: 1;
}

.home-section-dashboard-icon-select ul li,
.event-detail-select ul li {
  margin: 0rem;
  padding: $dimenXXS $dimenM;
  min-width: 170px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'calt' off;
  color: $black-1;
  white-space: nowrap;
  cursor: pointer;
  border: 0px;
  border-radius: 10px;
}

.home-section-dashboard-icon-select ul li:hover {
  color: $purple-dark;
}

.home-section-dashboard-icon-select ul li:active {
  background: $purple-light;
}

.home-section-dashboard-quantity {
  gap: $dimenXS;
}

.home-section-dashboard-quantity div {
  margin: 0rem;
  padding: $dimenXXXS $dimenXS;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $grey-upload-image;
  background: $grey-light-2;
  border-radius: 10px;
}

.orange-number {
  color: $orange !important;
}

.orange-background {
  background: $orange-background !important;
}

.home-section-events-vertical-container {
  width: 100%;
  gap: $dimenS;
}

.home-section-events-vertical,
.events-section-item {
  margin: 0rem;
  padding: $dimenS;
  width: 100%;
  background: $white-2;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 16px;
  gap: $dimenL;
}

.home-section-events-vertical-left,
.events-section-item-left {
  margin: 0rem;
  height: 72px;
  gap: $dimenL;
  flex-shrink: 1;
}

.home-section-events-vertical-right,
.events-section-item-right {
  height: 72px;
  flex-shrink: 0;
}

.home-section-events-vertical-date,
.events-section-item-date {
  margin: 0rem;
  width: 72px;
  height: 72px;
  background: $purple-light;
  border-radius: 14px;
  flex-shrink: 0;
}

.home-section-events-vertical-date h3,
.events-section-item-date h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $purple-dark;
}

.home-section-events-vertical-date h4,
.events-section-item-date h4 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $purple-dark;
}

.home-section-events-vertical img,
.events-section-item img {
  margin: 0rem;
  width: 72px;
  height: 72px;
  border-radius: 14px;
}

.home-section-events-vertical-line,
.events-section-item-line {
  margin: 0rem;
  width: 1px;
  height: 72px;
  background: $grey-event-line;
  flex-shrink: 0;
}

.home-section-events-vertical-info,
.events-section-item-info {
  gap: $dimenM;
  flex-shrink: 1;
}

.home-section-events-vertical-info {
  > img,
  > svg {
    flex-shrink: 0;
    margin: 0rem;
    width: 72px;
    height: 72px;
    border-radius: 14px;
    background: linear-gradient(247.42deg, #ebe8ff -3.75%, #432ec9 93.63%);
    box-shadow: 4px 4px 30px rgba(115, 115, 115, 0.25);
  }
  > img {
    object-fit: cover;
  }
}

.home-section-events-vertical-info-text,
.events-section-item-info-text {
  margin: 0rem;
  width: 265px;
  flex-shrink: 1;
}

.home-section-events-vertical-info-text {
  flex-grow: 1;
  * {
    width: 100%;
    max-width: fit-content;
    flex-shrink: 1;
  }
}

.home-section-events-vertical-info-text h2,
.events-section-item-info-text h2 {
  margin: 0rem;
  margin-bottom: $dimenXXXS;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: $grey-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-section-events-vertical-info-text h5,
.home-section-events-horizontal-bottom h5,
.home-section-more-item h5,
.events-section-item-info-text h5 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: $grey-upload-image;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-section-events-vertical-tickets,
.events-section-item-tickets {
  gap: $dimenXXXS;
  flex-shrink: 0;
}

.home-section-events-vertical-tickets h3,
.events-section-item-tickets h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: $grey-upload-image;
}

.home-section-events-vertical-tickets h4,
.events-section-item-tickets h4 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 120%;
  color: $grey-dark;
}

.home-section-events-vertical-tickets span,
.events-section-item-tickets span {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: $purple-dark;
}

.home-section-events-horizontal-container {
  margin: 0rem;
  width: 100%;
  background: $grey-event-background;
  gap: $dimenS;
}

.home-section-events-horizontal {
  position: relative;
  flex-grow: 1;
  margin: 0rem;
  width: calc(50% - 8px);
  min-width: 414px;
  height: 271px;
  padding: $dimenL;
  background-size: cover;
  box-shadow: 4px 4px 30px rgba(115, 115, 115, 0.25);
  border-radius: 18px;
  overflow: hidden;
  * {
    z-index: 1;
  }
  > img,
  > svg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  > img {
    object-fit: cover;
  }
}

.home-section-events-horizontal-bottom {
  width: 100%;
  max-width: 460px;
  .home-section-events-horizontal-bottom-title {
    max-width: 100%;
    margin-bottom: $dimenXS;
    gap: $dimenL;
    > .home-section-events-horizontal-bottom-title-box {
      max-width: calc(100% - 132px);
      * {
        width: 100%;
        max-width: calc(100% - 0px);
      }
    }
  }
}

.home-section-events-horizontal-bottom h2,
.home-section-more-item-title > h2 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  color: $white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-section-events-horizontal-bottom h2 span {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: $white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-section-events-horizontal-bottom h3,
.home-section-more-item-title h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 34px;
  color: $white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.black-date {
  background: $black;
}

.black-date h3,
.black-date h4 {
  color: $grey-event-background;
}

.home-section-events-horizontal-bottom h5 {
  color: rgba($grey-light-3, 0.8);
}

.home-section-more {
  margin: 0rem;
  margin-top: $dimenXXS;
  width: 100%;
  //max-width: 100%;
  gap: $dimenXL;
}

.home-section-more-title {
  margin: 0rem;
  width: 100%;
}

.home-section-more-title > h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: $black-1;
}

.home-section-more-title-right {
  flex-shrink: 0;
  gap: $dimenXXXS;
  cursor: pointer;
}

.home-section-more-title-right h4 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: $purple-dark;
}

.home-section-more-title-right svg {
  flex-shrink: 0;
  margin: 0rem;
  width: 24px;
  height: 24px;
  transform: rotate(270deg);
}

.home-section-more-horizontal-container {
  justify-content: flex-start;
  gap: $dimenS;
  width: 100%;
  max-width: calc(100vw - 312px - $dimenXXXL);
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.home-section-more-item {
  flex-shrink: 0;
  margin: 0rem;
  padding: $dimenS;
  width: 349px;
  background: $white-2;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 16px;
  gap: $dimenS;
}

.home-section-more-item-image {
  margin: 0rem;
  padding: $dimenS;
  width: 100%;
  min-width: auto !important;
  height: 200px;
  border: 0px;
  border-radius: 16px;
  background-size: cover;
  img,
  svg {
    width: 100%;
  }
}

.home-section-more-item-title h2,
.home-section-more-item-title h3 {
  color: $black-blue;
}

.home-section-more-item-info {
  margin: 0rem;
  padding: $dimenXXXS $dimenXS;
  height: 34px;
  border-radius: 17px;
  border: 0.75px solid $orange;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: $orange;
  cursor: pointer;
}

.home-section-more-item h5 {
  color: $grey-dark;
}

.home-section-more-item > button {
  border: 0px;
  background: $purple;
  color: $violet-dark;
}

@media (max-width: 1455px) {
  .home-section-more-horizontal-container {
    justify-content: start;
  }
}

@media (max-width: $mediaLG) {
  .home-section,
  .ticketer-section,
  .events-section,
  .my-tickets-section,
  .create-tickets-event-section {
    padding: $dimenS;
    gap: $dimenM;
  }

  .home-section-dashboard {
    gap: $dimenXXS;
  }

  .home-section-dashboard-box {
    min-width: 316px;
    flex: 1 1 128px;
  }

  .home-section-events-title h3 {
    font-size: 20px;
  }

  .home-section-events-box-empty {
    margin: $dimenXXXL $dimenXXS;
  }

  .home-section-events-box-empty > svg {
    width: 84px;
    height: 80px;
  }

  .home-section-events-box-empty-text h3 {
    font-size: 19px;
  }

  .home-section-events-box-empty-text h4 {
    font-size: 16px;
  }

  .home-section-events-box-empty-text p {
    font-size: 16px;
  }

  .home-section-events-box-empty-text p span {
    font-size: 16px;
  }

  .home-section-dashboard-icon-select {
    padding: $dimenXXXS;
    min-width: auto;
    font-size: 0;
  }

  .home-section-events-vertical {
    padding: $dimenXS;
    gap: $dimenXXS;
  }

  .home-section-events-vertical-right,
  .home-section-events-vertical-left {
    height: 58px;
  }

  .home-section-events-vertical-left {
    flex-shrink: 1;
    width: 100%;
    max-width: calc(100% - 112px);
    height: 58px;
    gap: $dimenXXS;
  }

  .home-section-events-vertical-date {
    flex-shrink: 0;
    width: 58px;
    height: 58px;
    border-radius: 12px;
  }

  .home-section-events-vertical img {
    flex-shrink: 0;
    margin: 0rem;
    width: 58px;
    height: 58px;
    border-radius: 12px;
  }

  .home-section-events-vertical-line {
    height: 58px;
  }

  .home-section-events-vertical-info {
    gap: $dimenXXS;
    width: 100%;
    flex-shrink: 1;
  }

  .home-section-events-vertical-info-text {
    width: 270px;
  }

  .home-section-events-vertical-info-text h2 {
    font-size: 16px;
    line-height: 22px;
  }

  .home-section-events-vertical-info-text h5 {
    font-size: 10px;
  }

  .home-section-dashboard-quantity {
    gap: $dimenXXS;
  }

  .home-section-dashboard-quantity div {
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
  }

  .home-section-more {
    margin-top: $dimenS;
    gap: $dimenXL;
  }

  .home-section-more-title > h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .home-section-more-title-right {
    gap: $dimenXXXS;
    cursor: pointer;
  }

  .home-section-more-title-right h4 {
    font-size: 14px;
  }

  .home-section-more-item {
    width: 284px;
    background: $white-2;
    border-radius: 12px;
  }

  .home-section-more-item-image {
    padding: $dimenXS;
    height: 160px;
    border-radius: 12px;
  }

  .home-section-more-item-info {
    padding: $dimenXXXS $dimenXXS;
    font-size: 14px;
  }

  .home-section-more-horizontal-container {
    justify-content: space-around;
    gap: $dimenS;
    max-width: calc(100vw - $dimenXL);
  }
}

@media (max-width: 918px) {
  .home-section-more-horizontal-container {
    justify-content: start;
  }
}

@media (max-width: $mediaMD) {
  .home-section-dashboard-box {
    min-width: 266px;
    flex: 1 1 128px;
  }
}

@media (max-width: $mediaXS) {
  .home-section-events-horizontal {
    width: 100%;
    min-width: auto;
    height: 314px;
  }

  .home-section-events-horizontal-bottom {
    max-width: fit-content;
    .home-section-events-horizontal-bottom-title {
      margin-bottom: $dimenM;
      gap: $dimenXXXS;
      > .home-section-events-horizontal-bottom-title-box {
        max-width: 100%;
      }
    }
  }

  .home-section-events-vertical-info-text {
    width: calc(100% - 100px);
  }

  .home-section-events-horizontal-bottom h2,
  .home-section-more-item-title > h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .home-section-events-horizontal-bottom h3,
  .home-section-more-item-title > h3 {
    font-size: 12px;
    line-height: 14px;
  }

  .home-section-events-horizontal-bottom {
    .home-section-events-horizontal-bottom-title {
      > .home-section-events-horizontal-bottom-title-box {
        max-width: calc(100% - 0px);
      }
    }
  }

  .home-section-dashboard-box {
    min-width: 178px;
  }
}

@media (max-width: 400px) {
  .home-section-dashboard-box {
    min-width: 167px;
  }
}

@media (max-width: 375px) {
  .home-section-events-vertical img {
    display: none;
  }

  .home-section-events-vertical-info-text {
    //width: 100%;
    //max-width: calc(100% - 12px);
  }
}

@media (max-width: 300px) {
  .home-section-events-vertical-line {
    display: none;
  }

  .home-section-events-vertical-info-text {
    //max-width: 100px;
  }
}

// TICKETER

.ticketer-section,
.my-tickets-section,
.create-tickets-event-section {
  gap: $dimenXXL;
}

.ticketer-section {
  min-height: 100vh;
}

.ticketer-section-content,
.my-tickets-content {
  width: 100%;
  gap: $dimenXL;
}

.ticketer-section-content > p,
.events-section-content > p {
  margin: 0rem;
  margin-bottom: $dimenXXS;
  font-family: $font2;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: $grey-navbar;
}

.ticketer-section-top,
.ticketer-section-bottom,
.my-tickets-content-top {
  gap: $dimenS;
}

.ticketer-section-top h3,
.ticketer-section-bottom h3,
.my-tickets-content-top h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $grey-navbar;
}

.ticketer-section-container {
  gap: $dimenXL;
}

.ticketer-sections-section {
  width: 100%;
}

.ticketer-sections-container {
  margin: 0rem;
  width: 100%;
  max-width: 684px;
  gap: $dimenXL;
}

.ticketer-sections-title-left {
  gap: $dimenXXS;
}

.ticketer-sections-container h2 {
  margin: 0rem;
  margin-bottom: 50px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: $black-1;
}

.ticketer-sections-container h2 span {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 120%;
  color: $black-1;
}

.ticketer-sections-title {
  gap: $dimenS;
}

.ticketer-sections-container h3 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: $grey-dark;
}

.ticketer-sections-container h4 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  color: $violet-dark;
  //text-align: center;
}

.ticketer-sections-title p {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  color: $grey-upload-image;
}

.terms-and-policy-actions {
  gap: $dimenXXXL;
}

.terms-and-policy-text {
  gap: $dimenXXL;
}

.ticketer-sections-advice {
  margin: 0rem;
  padding: $dimenS $dimenL;
  width: 100%;
  background: $purple-light;
  border-radius: 8px;
  font-family: $font2;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: $purple-dark;
  gap: $dimenS;
}

.ticketer-sections-advice svg {
  flex-shrink: 0;
  margin: 0rem;
  width: 24px;
  height: 24px;
}

.ticketer-sections-advice svg path {
  fill: $purple-dark;
}

.terms-and-policy-text-buttons {
  gap: $dimenS;
}

.terms-and-policy-text-buttons button:nth-child(1) {
  background: $purple-light-2;
  border: 1px solid $purple-dark;
  color: $purple-dark;
}

.terms-and-policy-text-buttons button:nth-child(2) {
  background: $violet;
  border: 0px;
  color: $purple-light;
}

.terms-and-policy-text-buttons button:nth-child(2):disabled {
  opacity: 0.2;
}

.ticketer-sections-counter {
  margin: 0rem;
  padding: $dimenXXS;
  width: 57px;
  height: 30px;
  background: $orange-background;
  border-radius: 10px;
  border: 0px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: $orange;
}

@media (max-width: $mediaLG) {
  .ticketer-sections-section {
    padding: $dimenXL $dimenS;
  }

  .ticketer {
    font-size: 24px;
    line-height: 38px;
    letter-spacing: -1px;
  }

  .ticketer-section,
  .my-tickets-section {
    gap: $dimenS;
  }

  .ticketer-section-container {
    gap: $dimenS;
  }

  .ticketer-section-content,
  .my-tickets-content {
    gap: $dimenS;
  }

  .ticketer-section .bar-section-buttons-right {
    gap: $dimenXXS;
  }

  .ticketer-sections-container {
    gap: $dimenS;
  }

  .ticketer-sections-container h2 {
    margin-bottom: $dimenS;
    font-size: 22px;
    line-height: 23px;
  }

  .ticketer-sections-container h2 span {
    font-size: 22px;
    line-height: 23px;
  }

  .ticketer-sections-container h3 {
    font-size: 18px;
    line-height: 23px;
  }

  .ticketer-sections-container h4 {
    font-size: 14px;
  }

  .terms-and-policy-actions {
    gap: $dimenL;
  }

  .terms-and-policy-text {
    gap: $dimenXL;
  }

  .ticketer-sections-advice {
    font-size: 14px;
  }
}

//TICKETER PAGES
.ticketer-sections-domain-container {
  margin: 0rem;
  width: 100%;
  gap: $dimenM;
}

.ticketer-sections-domain-item {
  margin: 0rem;
  padding: $dimenM $dimenXL;
  width: calc(50% - ($dimenM / 2));
  min-width: 332px;
  gap: $dimenXXS;
  background: $white;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 12px;
}

.ticketer-sections-domain-item > button {
  padding: $dimenXXXS $dimenL;
  width: 100%;
  border: 0px;
  background: $purple;
  color: $violet-dark;
  line-height: 20px;
}

.ticketer-sections-domain-item > h4 {
  margin: 0rem;
  margin-bottom: calc($dimenXXXS / 2);
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  color: $black-1;
}

.ticketer-sections-domain-item > p {
  margin: 0rem;
  margin-bottom: $dimenS;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  color: $grey-blue;
}

.domain-purple {
  color: $purple-dark !important;
}

.ticketer-field-thick {
  height: 82px;
}

.ticketer-field-domain-container {
  position: relative;
}

.ticketer-field-domain-container h3 {
  position: absolute;
  top: 29px;
  right: 20px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $purple-domain;
}
.service-change-input {
  position: relative;
}
.service-change-input h3 {
  position: absolute;
  top: 50%;
  right: 5%;
  font-family: 'Sequel Sans Book Head', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6b53ff;
  transform: translate(0%, -50%);
}

.modal-cancel-ticket-sold {
  background-attachment: white;
  position: absolute;
  width: 100%;
}
.ticketer-field-domain-container input {
  padding-right: $dimenXXXXL;
}

.ticketer-field::placeholder {
  font-weight: 400;
}

.ticketer-sections-payment-container {
  gap: $dimenL;
}

.ticketer-sections-payment-item {
  margin: 0rem;
  padding: $dimenXS $dimenL;
  width: 100%;
  height: 64px;
  background: $white;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 12px;
  cursor: pointer;
}

.ticketer-sections-payment-item:hover {
  border: 1px solid $purple-dark;
}

.ticketer-sections-payment-item:active {
  border: 1px solid $purple-dark;
  opacity: 0.8;
}

.ticketer-sections-payment-item-left {
  gap: $dimenXS;
}

.ticketer-sections-payment-item-left p {
  margin: 0rem;
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
}

.ticketer-field-info-container,
.ticketer-field-info-email {
  gap: $dimenS;
}

.ticketer-field-info-container {
  .ticketer-customize-picture-item-data {
    width: 100%;
    > label {
      width: fit-content;
    }
  }
  .ticketer-customize-picture-item-picture {
    width: 104px;
    height: 104px;
    padding: $dimenXXXS;
    > img {
      width: 100%;
      height: 100%;
    }
  }
}

.third-page-content {
  width: 100%;
  gap: $dimenXXS !important;
}

.ticketer-field-info-email > div {
  position: relative;
}

.ticketer-field-info-email > div {
  width: calc(50% - $dimenXXS);
}

.ticketer-field-info-container h5 {
  margin: 0rem;
  margin-bottom: $dimenXS;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $grey-navbar;
}

.description-counter {
  text-align: right;
}

#ticketer-description,
#event-description {
  min-height: 168px;
  max-height: 420px;
}

.ticketer-select {
  color: $grey-upload-image;
}

.select-black {
  color: $black-1;
}

.ticketer-select option {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
}

.ticketer-select option:checked {
  background-color: $purple-light;
  color: $purple-dark;
}

.ticketer-field-social {
  margin: 0rem;
  margin-bottom: $dimenXXS;
  gap: $dimenXXS;
}

.ticketer-field-social h5 {
  margin: 0rem;
}

.ticketer-field-social svg {
  flex-shrink: 0;
  margin: 0rem;
  width: 35px;
  height: 35px;
}

.ticketer-field-info-email > div > svg {
  position: absolute;
  top: 75px;
  right: 16px;
  path {
    fill: $purple-dark;
  }
}

#ticketer-fb,
#ticketer-ig,
#ticketer-tw,
#ticketer-tt {
  padding-right: $dimenXXL;
}

.ticketer-customizate {
  max-width: 2000px;
}

.ticketer-customizate > section,
.ticketer-customizate > div {
  width: 50%;
}

.ticketer-customizate > section {
  padding-left: $dimenXL;
  padding-right: $dimenXL;
}

.ticketer-customizate > * {
  flex: 1;
  align-self: stretch;
}

.ticketer-customizate-preview {
  margin: 0rem;
  padding: $dimenS;
  max-height: 812px;
  align-self: stretch;
}

.ticketer-customizate-preview-box {
  margin: 0rem;
  padding: $dimenS;
  width: 100%;
  height: 100%;
  background: $grey-light-2;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.desktop-preview {
  gap: $dimenXXXXL;
  > svg {
    max-width: 95%;
  }
  iframe {
    position: absolute;
    margin: 0rem;
    width: 1180px;
    height: 758px;
    zoom: 1;
    -moz-transform: scale(0.5);
    //-moz-transform-origin: 0 0;
    -o-transform: scale(0.5);
    //-o-transform-origin: 0 0;
    -webkit-transform: scale(0.5);
    //-webkit-transform-origin: 0 0;
  }
}

.ticketer-customizate-preview-box-desktop {
  width: 100%;
  height: 100%;
  padding-top: 72px;
}

.mobile-preview {
  > svg {
    position: absolute;
    bottom: 0px;
  }
  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0rem;
    width: 100%;
    height: 100%;
    zoom: 1;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
    -o-transform: scale(1);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(1);
    -webkit-transform-origin: 0 0;
  }
}

.ticketer-customizate-preview-box-bar {
  margin: 0rem;
  padding: $dimenXS;
  width: 333px;
  height: 48px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 1px solid $grey-light;
  background: $white;
  color: $grey-upload-image;
  font-family: $font1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 148.541% */
  letter-spacing: -0.16px;
  cursor: pointer;
}

.ticketer-customizate-preview-box-bar:hover {
  opacity: 0.8;
}

.ticketer-customizate-preview-box-bar:active {
  opacity: 0.6;
}

.ticketer-customize-picture-container {
  gap: $dimenL;
}

.ticketer-customize-picture-item {
  margin: 0rem;
  padding: $dimenXS;
  width: 100%;
  height: 115px;
  background: $white;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 12px;
  gap: $dimenXL;
}

.ticketer-customize-picture-item-picture {
  flex-shrink: 0;
  margin: 0rem;
  width: 87px;
  height: 87px;
  background: $grey-blue-2;
  border-radius: 8px;
}

.ticketer-customize-picture-item-picture img {
  border-radius: 8px;
  width: 79px;
  height: 79px;
  object-fit: cover;
}

.ticketer-customize-picture-item-data {
  margin: 0rem;
  width: calc(100% - $dimenXXXL - $dimenXXXL);
  height: 100%;
}

.ticketer-customize-picture-item-data h5 {
  margin: 0rem;
  width: 100%;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticketer-customize-picture-item-data label,
.ticketer-customize-picture-item-data button,
.modal-ticketer-item-content-picture-right button {
  margin: 0rem;
  padding: $dimenXXXS $dimenM;
  height: 37px;
  border: 0px;
  background: $purple-light-2;
  border-radius: 10px;
  font-size: 14px;
  line-height: 120%;
  color: $purple-dark;
  box-shadow: none;
  cursor: pointer;
  gap: $dimenXXS;
  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    path {
      fill: $purple-dark;
    }
  }
}

.ticketer-customize-picture-item-data label:hover {
  opacity: 0.8;
}

.ticketer-customize-picture-item-data label:active {
  opacity: 0.6;
}

.ticketer-customize-picture-item-data label {
  width: 184px;
}

.ticketer-customize-picture-item-data button,
.modal-ticketer-item-content-picture-right button {
  width: 37px;
  padding: 0rem;
}

@media (max-width: $mediaXL) {
  .ticketer-customizate > section {
    width: 100%;
  }
}

@media (max-width: $mediaLG) {
  .ticketer-sections-domain-container {
    gap: $dimenS;
  }

  .ticketer-sections-domain-item {
    width: 100%;
    min-width: auto;
    max-width: 432px;
  }

  .ticketer-sections-domain-item > button {
    padding: $dimenXXXS $dimenXXS;
  }

  .ticketer-sections-payment-container {
    gap: $dimenS;
  }

  .ticketer-field-info-email > div {
    width: 100%;
  }

  .ticketer-customizate > section {
    padding-left: $dimenS;
    padding-right: $dimenS;
  }

  .ticketer-customize-picture-container {
    gap: $dimenS;
  }

  .ticketer-customize-picture-item {
    padding: $dimenXXS;
    height: 122px;
    gap: $dimenXS;
  }

  .ticketer-customize-picture-item-data {
    width: calc(100% - $dimenXXXXL - $dimenM);
  }

  .ticketer-customize-picture-item-picture {
    width: 103px;
    height: 103px;
    border-radius: 10px;
  }

  .ticketer-customize-picture-item-picture img {
    width: 94px;
    height: 94px;
  }

  .ticketer-customize-picture-item-data label,
  .ticketer-customize-picture-item-data button {
    padding: $dimenXXXS;
    font-size: 12px;
  }

  .ticketer-customize-picture-item-data label {
    width: 152px;
    gap: $dimenXXS;
  }
}

@media (max-width: 374px) {
  .ticketer-customize-picture-item-data label,
  .ticketer-customize-picture-item-data button {
    font-size: 0px;
  }

  .ticketer-customize-picture-item-data {
    width: 37px;
    gap: 0px;
  }
}

//EVENTS
.events-mobile {
  width: 100%;
  min-width: 1044px;
  max-height: max-content;
}

.events-section-content {
  width: 100%;
  gap: $dimenXL;
}

.events-section-content > p {
  margin: 0rem;
}

.events-section-bar {
  gap: $dimenXXS;
}

.events-section-bar > h3 {
  flex-shrink: 0;
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: $black-1;
}

.events-section-bar-right,
.events-section-showevents {
  gap: $dimenXXS;
}

.events-section-bar-right {
  gap: $dimenXXS;
  width: 100%;
}

.events-section-bar-button {
  margin: 0rem;
  width: 152px;
  height: 40px;
  background: $purple-light-2;
  border: 0.55px solid $purple-dark;
  border-radius: 20px;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  color: $purple-dark;
}

.events-section-bar-button-active {
  border: 0px;
  background: $purple-domain;
  color: $purple-light;
}

.events-section-pending {
  margin: 0rem;
  padding: $dimenXXS $dimenXS;
  width: 100%;
  height: 36px;
  border: 1px dashed $grey-blue;
  border-radius: 9px;
  gap: $dimenXXXS;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.01em;
  color: $grey-upload-image;
  svg {
    margin-right: $dimenXXXS;
  }
  div {
    margin: 0rem 0.125rem 0.0625rem 0.125rem;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $violet;
    font-size: 10px;
    line-height: 12px;
    color: $white;
  }
}

.events-section-item-right {
  gap: $dimenXXL;
}

.events-section-item-info-image,
.ticket-detail-no-image {
  flex-shrink: 0;
  margin: 0rem;
  background: linear-gradient(247.42deg, #ebe8ff -3.75%, #432ec9 93.63%);
}

.events-section-item-info-image {
  box-shadow: 4px 4px 30px rgba(115, 115, 115, 0.25);
}

.events-section-item-info-image {
  width: 72px;
  height: 72px;
  border-radius: 14px;
}

.ticket-detail-no-image {
  width: 63px;
  height: 63px;
  border-radius: 6px;
  > img {
    max-width: 63px;
    max-height: 63px;
  }
}

.events-section-item-info-image img {
  margin: 0rem;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  object-fit: cover;
}

.events-section-item-info-text {
  gap: $dimenXXXS;
}

.events-section-item-right .event-button {
  font-size: 14px;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  gap: 0.25rem;
}

.create-event-section-container > div {
  width: calc(50% - $dimenS);
  flex-grow: 0;
}

.create-event-section-title,
.delete-event-left {
  gap: $dimenXXS;
}

.create-event-section-title p,
.delete-event p {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $grey-navbar;
}

.delete-event {
  margin: 0rem;
  width: 100%;
}

.delete-event h5 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  text-decoration-line: underline;
  font-feature-settings: 'calt' off;
  color: $purple-dark;
  cursor: pointer;
}

.detail-event-section {
  gap: $dimenXL;
}

.detail-event-info,
.detail-event-cards,
.detail-event-metrics,
.detail-event-discounts,
.detail-event-delete {
  width: 100%;
}

.detail-event-info {
  position: relative;
  margin: 0rem;
  padding: $dimenL $dimenXL;
  width: 100%;
  background: $white-2;
  box-shadow: 0px 4px 50px rgba(204, 204, 204, 0.1);
  border-radius: 18px;
  gap: calc($dimenXXL + $dimenXS);
}

.detail-event-info-status {
  position: absolute;
  top: 24px;
  right: 0px;
  margin: 0rem;
  padding: $dimenXXS;
  width: 173px;
  height: 33px;
  border-radius: 10px 0px 0px 10px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  font-feature-settings: 'calt' off;
}

.detail-event-info-status-green {
  background: $green-background;
  color: $green-2;
}

.detail-event-info-status-red {
  background: $red-light;
  color: $red-status;
}

.detail-event-info-name h2 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  color: $grey-event-title;
}

.detail-event-info-data {
  gap: $dimenXL;
}

.detail-event-info-data-item {
  margin: 0rem;
  width: 228px;
  gap: $dimenL;
  svg {
    flex-shrink: 0;
    margin: 0rem;
    width: 40px;
    height: 40px;
    path {
      fill: $grey-event-icon;
    }
  }
}

.detail-event-info-data-item-text {
  margin: 0rem;
  min-height: 52px;
  gap: $dimenXS;
}

.detail-event-info-data-item-text h5 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $black-blue;
}

.detail-event-info-data-item-text h4 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $grey-blue-1;
}

.detail-event-cards {
  gap: $dimenXL;
}

.detail-event-qr {
  border-radius: 18px;
  padding: 1.5rem 2.5rem;
  background-color: white;
}

.detail-event-card {
  position: relative;
  align-self: stretch;
  margin: 0rem;
  padding: $dimenL $dimenXXL;
  width: calc(50% - $dimenS);
  box-shadow: 0px 4px 50px rgba(204, 204, 204, 0.1);
  border-radius: 18px;
  overflow: hidden;
  > svg {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  > svg:nth-child(2) {
    right: 36px;
  }
  h3 {
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    z-index: 1;
  }
  p {
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    z-index: 1;
  }
}

.detail-event-card-purple {
  background: linear-gradient(136.85deg, #7d68ff -24.95%, #ffffff 131.4%);
  h3,
  p {
    color: $blue-event-card;
  }
  .detail-event-card-bottom svg {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    path {
      fill: $violet;
    }
    circle {
      fill: #ad9fff;
    }
  }
}

.detail-event-card-orange {
  background: linear-gradient(135.97deg, #f27327 -18.12%, #ffffff 125.48%);
  h3,
  p {
    color: $orange-event-card;
  }
  h4 {
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-decoration-line: underline;
    font-feature-settings: 'calt' off;
    color: $orange-event-card-2;
    cursor: pointer;
  }
  .detail-event-card-bottom svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    path {
      fill: $orange-event-card-2;
    }
  }
}

.detail-event-card-bottom-icons {
  gap: $dimenXS;
}

.detail-event-metrics,
.detail-event-discounts {
  gap: $dimenL;
}

.detail-event-metrics h3,
.detail-event-discounts h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $black-blue;
}

.detail-event-metrics h3 {
  line-height: 30px;
}

.detail-event-metrics-container {
  gap: $dimenS;
}

.event-detail-select {
  width: 348px;
  height: 44px;
}

.event-detail-select ul {
  top: 52px;
  right: 0px;
  margin: 0rem;
  width: 348px;
  background: $white;
  border: 1px solid $purple-dark;
  box-shadow: 4.22823px 4.22823px 31.7117px rgba(213, 213, 213, 0.25);
  border-radius: 12px;
  z-index: 3;
  max-height: 196px;
  overflow-y: auto;
}

.event-detail-select ul li {
  margin: 0rem;
  padding: $dimenXXS $dimenS;
  width: 100%;
  height: 44px;
  line-height: 28px;
  background: $white;
  border-radius: 10px;
  font-family: $font1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
}

.event-detail-select ul li:hover {
  color: $purple-dark;
}

.event-detail-select ul li:active {
  background: $purple-light;
}

.detail-event-discounts-bar {
  margin: 0rem;
  padding: $dimenS $dimenL;
  width: 100%;
  height: 81px;
  background: $orange-background;
  border-radius: 8px;
  gap: $dimenL;
}

.detail-event-discounts-bar-left {
  gap: $dimenS;
  font-family: $font3;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: $orange;
}

.detail-event-discounts button {
  margin: 0rem;
  padding: $dimenXXS $dimenXXL;
  height: 48px;
  background: $orange-background;
  border: 1px solid $orange;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  color: $orange;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 32px;
  font-feature-settings: 'calt' off;
}

.detail-event-delete {
  gap: $dimenS;
}

.detail-event-delete h3 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: $grey-navbar;
}

.create-tickets-bottom {
  margin: 0rem;
  padding: 0rem;
  width: 100%;
  gap: $dimenL;
  > p {
    margin: 0rem;
    font-family: $font2;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: $grey-navbar;
  }
}

.create-tickets-container {
  margin: 0rem;
  padding: 0rem;
  width: 100%;
  gap: $dimenXL;
  > div {
    cursor: pointer;
  }
  > div:hover {
    opacity: 0.8;
  }
  > div:active {
    opacity: 0.6;
    border: 1px solid $violet;
  }
}

.details-ticket-section {
  padding: $dimenXL;
  gap: $dimenXXL;
  width: 100%;
  > section {
    padding: 0rem;
    > div {
      max-width: none;
      h2 {
        margin: 0rem;
      }
    }
  }
}

.ticket-detail-info {
  width: 100%;
  gap: $dimenXL;
  > p {
    margin: 0rem;
    font-family: $font2;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: $grey-navbar;
  }
  > div {
    width: 100%;
    max-width: none;
  }
}

.ticket-detail-info .modal-ticketer-item-content {
  gap: $dimenS;
}

.modal-ticketer-item-content .modal-ticketer-item-info {
  //width: calc(25% - 12px);
}

.ticket-detail-info
  .modal-ticketer-item-content-info-container:nth-child(2)
  .modal-ticketer-item-info:nth-child(2),
.ticket-detail-info
  .modal-ticketer-item-content-info-container:nth-child(3)
  .modal-ticketer-item-info:nth-child(2) {
  width: calc(75% - 8px);
}

.ticket-detail-info .orange-background > svg {
  flex-shrink: 0;
  width: 42px;
  height: 42px;
}

.details-ticket-section .ticketer-sections-title-left {
  cursor: pointer;
}

.discount-table-container {
  width: 100%;
  gap: $dimenXXS;
}

.discount-table-title {
  padding: $dimenXXXS 0rem $dimenXXXS $dimenS;
  width: 100%;
}

.discount-table-title-left {
  width: calc(100% - 57px);
}

.discount-table-title-left > h6 {
  margin: 0rem;
  width: calc((100% - 57px) / 4);
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: $black-blue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discount-table-row {
  padding: $dimenXS 0rem $dimenXS $dimenS;
  width: 100%;
  height: 41px;
  border: 0px;
  border-radius: 0px;
}

.discount-table-row:nth-child(even) {
  background: $grey-light-3;
  border-radius: 8px;
}

.discount-table-row-left {
  width: calc(100% - 57px);
}

.discount-table-row-left > h6 {
  margin: 0rem;
  width: calc((100% - 57px) / 4);
  font-family: $font1;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $grey-navbar;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discount-table-row-right {
  margin: 0rem;
  width: 57px;
  height: 41px;
  background: $grey-modal-border;
  border-radius: 8px;
  cursor: pointer;
}

.discount-table-row-right.deactive > svg {
  transform: rotate(180deg);
  path {
    fill: $grey-event-icon;
  }
}

.ticketer-field-info-container p {
  font-family: $font2;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: $grey-navbar;
}

.ticketer-field-info-container .checkbox-register-container,
.checkbox-percentage {
  label {
    margin: 0rem;
    font-family: $font2;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -1px;
    color: $grey-navbar;
  }
}

.ticketer-field-info-container .checkbox-register-container {
  margin: 0rem;
  margin-top: $dimenXS;
}

.disabled-input {
  opacity: 0.2;
  cursor: default;
}

.create-ticket-third-page {
  gap: $dimenL;
  > div {
    gap: $dimenS;
    > div {
      gap: $dimenS;
      > div {
        width: calc(50% - $dimenXXS);
      }
    }
  }
}

.ticketer-field-info-container h4 {
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $grey-dark;
}

@media (max-width: $mediaLG) {
  .ticket-detail-buttons {
    > div {
      flex-direction: row !important;
      > button {
        min-width: 200px;
      }
    }
  }
}

//MY TICKETS
.my-tickets-container {
  margin: 0rem;
  padding: 0rem;
  width: 100%;
  gap: $dimenS;
  overflow-x: auto;
  > div {
    //cursor: pointer;
  }
  > div:hover {
    //opacity: 0.8;
  }
  > div:active {
    //opacity: 0.6;
  }
}

.my-tickets-content-top > h4 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: $black-1;
}

.text-error-red {
  font-weight: 700 !important;
  color: $red-error !important;
}

.my-ticket-detail {
  width: 100%;
  > h3 {
    margin: 0rem;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: $black-1;
  }
  .modal-ticketer-item-content-picture {
    cursor: pointer;
  }
  .modal-ticketer-item-content-picture:hover {
    opacity: 0.8;
  }
  .modal-ticketer-item-content-picture:active {
    opacity: 0.6;
  }
  .ticket-opened-qr-button {
    gap: $dimenXS;
  }
  .ticket-opened-qr-button button {
    width: 100%;
    background: $purple;
    color: $violet-dark;
    border: 0px;
  }
  .ticket-opened-qr-button button:disabled {
    background: $purple-off;
    color: $white;
    cursor: default;
    svg {
      path {
        fill: $white;
      }
    }
  }
  > button {
    border: 0px;
    width: 100%;
    background: $purple-domain;
    color: $white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
  > button:disabled {
    background: $grey-light;
  }
  .ticket-opened-qr-button button:disabled:hover,
  .ticket-opened-qr-button button:disabled:active,
  > button:disabled:hover,
  > button:disabled:active {
    opacity: 1;
  }
  .ticket-opened-qr-text {
    position: relative;
    padding: $dimenXXS $dimenS;
    gap: $dimenXXXS;
    width: 100%;
    height: 41px;
    background: $purple-dark;
    border: 0px;
    border-radius: 14px;
    > svg:first-child {
      position: absolute;
      top: -6px;
      left: calc(50% - 8px);
    }
    > svg:last-child {
      path {
        fill: $white;
      }
    }
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: $white;
  }
  .home-section-events-horizontal {
    height: 230px;
    gap: $dimenXXXS;
  }
  .ticket-opened-qr-img {
    position: relative;
    margin: 0rem;
    width: 100%;
    min-width: 414px;
    height: 230px;
    padding: $dimenXXL;
    box-shadow: 4px 4px 30px rgba(115, 115, 115, 0.25);
    border-radius: 18px;
    background: $white;
    img {
      margin: 0px;
      width: 150px;
      height: 150px;
    }
    div {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 43px;
      height: 43px;
      background: $purple;
      border: 0px;
      border-radius: 50%;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.ticket-content-max {
  margin: 0rem;
  padding: $dimenM;
  width: 100%;
  max-width: 420px;
  gap: $dimenL;
  background: $white-2;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 20px;
  .ticket-content-top {
    gap: $dimenS;
    margin: 0rem;
    width: 100%;
    > * {
      margin: 0rem;
      width: 100%;
    }
    .ticket-content-top-bar {
      font-family: $font1;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: $violet;
      svg {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        cursor: pointer;
        path {
          fill: $black-blue;
        }
      }
    }
  }
  .ticket-content-frame {
    height: 382px;
    background: $purple-off;
    border-radius: 12px;
    border: 0px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    audio {
      width: 100%;
      height: 100%;
    }
    embed {
      width: 100%;
      height: 100%;
    }
  }
  .ticket-content-desc {
    gap: calc($dimenXXXS / 2);
    h3 {
      margin: 0rem;
      font-family: $font3;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: $black-blue;
    }
    p {
      margin: 0rem;
      font-family: $font3;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $black;
    }
  }
  .ticket-content-bottom {
    gap: $dimenS;
    cursor: pointer;
    h3 {
      margin: 0rem;
      font-family: $font1;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-decoration-line: underline;
      font-feature-settings: 'calt' off;
      color: $orange-event-card-2;
    }
    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      path {
        fill: $orange-event-card-2;
      }
    }
  }
}

.ticket-sale-detail-main .profile-upload-image-mobile {
  max-width: none !important;
  > div {
    gap: $dimenXL;
    > .ticket-sale-detail-modal-text,
    .ticket-sale-detail-modal-buttons {
      width: 100%;
      max-width: 420px;
      gap: $dimenS;
    }
    .ticket-sale-detail-modal-text {
      h3 {
        margin: 0rem;
        font-family: $font4;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;
        font-feature-settings: 'calt' off;
        color: $black-1;
      }
      div {
        margin: 0rem;
        width: auto;
        font-family: $font3;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.01em;
        color: $grey-dark;
        display: flex;
        justify-content: center;
        gap: $dimenXXS;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          margin: 0rem;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background: $violet;
          font-family: $font1;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.02em;
          color: $grey-modal-border;
        }
      }
    }
    .ticket-sale-detail-modal-buttons {
      button:first-child {
        background: $purple-domain;
        color: $white;
      }
      button:last-child {
        background: $purple;
        color: $violet-dark;
      }
    }
  }
}

@media (max-width: $mediaLG) {
  .my-ticket-detail {
    .home-section-events-horizontal {
      min-width: 414px;
    }
    .ticket-opened-qr-img {
      min-width: 414px;
    }
  }
}

@media (max-width: $mediaXS) {
  .my-ticket-detail {
    .ticket-opened-qr-text {
      font-size: 14px;
    }
    .home-section-events-horizontal {
      min-width: auto;
    }
    .ticket-opened-qr-img {
      min-width: auto;
    }
  }
}

/* SUBSCRIPTIONS */

.sub-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1440px;

  .home-section-dashboard-box p {
    text-wrap: wrap;
    color: #646464;
    font-family: $font2;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
  .home-section-dashboard-box {
    position: relative;
    min-height: 100%;
  }
}

.sub-dashboard-bar {
  display: flex;
  align-items: center;
  gap: 16px;

  &-cancelPlan {
    color: #816cff;
    font-family: $font2;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    line-height: 25.375px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.sub-cards {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.sub-progress-bar {
  background-color: $red-status;
}

.sub-upgrade-activeplan {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $mediaLG) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    h2 {
      text-align: center;
    }
  }
}

.sub-save-percent {
  color: $violet;
  display: flex;
  padding: 9px 22px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.sub-cards-types {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  height: 100%;
  padding: 25px 0;

  .swiper {
    padding: 42px 0;
  }

  .home-section-dashboard-box h3 {
    font-size: 24px;
  }

  .home-section-dashboard-box.isPro {
    background: #f1effb;
    &::before {
      z-index: -1;
      content: '';
      border-radius: 18px;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        #442ecb 0%,
        #c8bfff 35%,
        #c8bfff3a 100%
      );
    }
  }
  .home-section-dashboard-box.isCurrentPro {
    background: inherit;
    &::before {
      z-index: -1;
      content: '';
      border-radius: 18px;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        #442ecb 0%,
        #c8bfff 35%,
        #c8bfff3a 100%
      );
    }
  }
  .home-section-dashboard-box.isEmpresa {
    &::before {
      z-index: -1;
      content: '';
      border-radius: 18px;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        #a8a7b5 0%,
        #c5c3d0 35%,
        #c5c3d05c 100%
      );
    }
  }
}

.sub-cards-types-price {
  color: #484848;
  font-family: $font1;
  font-size: 40px;
  font-weight: bold;
  line-height: 46px;
  margin-bottom: 10px;
}

.sub-included > p {
  color: #0f0f0f !important;
}

.sub-list-attributes {
  list-style: circle;
  li {
    color: #484848;
    font-family: $font2;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    margin-bottom: 8px;
  }
}

.sub-label-pro {
  width: auto;
  display: inline-flex;
  height: 33px;
  padding: 4px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  border-radius: 42px;
  background: #c8bfff;
  position: absolute;
  top: -20px;
  right: 20px;
}

.sub-label-empresa {
  width: auto;
  position: absolute;
  top: -40px;
  right: 20px;
}

.subscription-cancel-button {
  display: flex;
  height: 52px;
  padding: 16px 46px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 14px;
  border: 1px solid #816cff;
  /* background-color: #fff; */
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05) inset, 0px 4px 15px 0px rgba(0, 0, 0, 0.08), 0px 2px 2px 0px rgba(0, 0, 0, 0.02); */
  color: #816cff;
}

.home-section-dashboard-box .statistics-cards {
  color: #f27327;
  text-align: center;
  font-family: $font2;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 14px; /* 87.5% */
}

.disclaimer-message {
  margin: 0rem;
  padding: 0.75rem;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 12px;
  gap: 2rem;
  display: flex;
  align-items: center;
  color: var(--Gris-Oscuro-1, #646464);
  font-family: $font2;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 25.375px; /* 253.75% */
}

.faq-card {
  border-radius: 12px;
  background: #fff;

  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.faq-card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: $font1;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%; /* 26.4px */
  border-bottom: 1px solid #dedede;
  padding-bottom: 1rem;
  width: 100%;
}

.faq-card-header-intro {
  display: flex;
  align-items: center;
  gap: 8px;
}

.faq-card-ul {
  padding: 0;
  padding: 12px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.faq-card-ul a {
  color: black;
  text-decoration: none;
  width: 100%;
}

.faq-card-ul a:hover {
  color: black;
  text-decoration: none;
}

.faq-card-li {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  font-family: $font1;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.767px; /* 148.541% */
  letter-spacing: -0.16px;
  cursor: pointer;
  width: 100%;
}

.faq-card-li:hover {
  width: 100%;
  background: #f1effb;
  color: #2a1b86;
}

.faqsid {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.faqsid-header {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: $font1;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%; /* 26.4px */
  border-bottom: 1px solid #dedede;
  padding-bottom: 1rem;
  width: 100%;
}

.faqsid-answer {
  font-family: $font1;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
  letter-spacing: -0.2px;
}

.faqsid-link {
  color: black;
  font-family: $font1;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 19.2px */
}

.faqsid-link svg {
  transform: rotate(-180deg);
}
.faqsid-link a {
  color: black;
  text-decoration: none;
  font-weight: 800;
  display: flex;
  gap: 0.5rem;
}

.faqsid-link a:hover {
  color: black;
  text-decoration: none;
  font-weight: 800;
}

.breadcumb-faq {
  width: 21ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@import 'styles.scss';

.modal-background {
  position: fixed; /* Stay in place */
  z-index: 6; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background: rgba($black-1, 0.4);
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - $dimenS - $dimenS);
}

@media (max-width: $mediaXS) {
  .modal-background-ticketer > div {
    min-height: auto;
  }
}

//MODAL TERMS
.modal-terms-container,
.modal-ticketeritems-container {
  margin: $dimenS;
  padding: $dimenXXXL;
  width: calc(100% - $dimenL - $dimenL);
  max-width: 1152px;
  height: auto;
  background: $white-2;
  border: 0px;
  border-radius: 14px;
  gap: $dimenS;
}

.modal-terms-container svg,
.modal-ticketeritems-container svg {
  margin-bottom: $dimenL;
}

.modal-terms-container p {
  margin-bottom: $dimenS;
}

.modal-terms-container-welcome {
  margin-bottom: $dimenXXL;
  width: 100%;
  max-width: 580px;
  gap: $dimenXL;
}

.modal-terms-container-welcome h2 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: $black-1;
}

.modal-terms-container-welcome p {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
}

.modal-terms-container-welcome h4 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: $purple-dark;
}

.modal-terms-container-boxes {
  margin: 0rem;
  gap: $dimenXXXL;
}

.modal-terms-container-boxes div {
  margin: 0rem;
  padding: $dimenXXL $dimenL;
  width: 292px;
  height: 292px;
  background: $grey-mail-box;
  border: 1px solid $grey-mail-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 20px;
  gap: $dimenS;
}

.modal-terms-container-boxes div:hover {
  background: $purple-light;
  border: 1px solid $purple-text;
}

.modal-terms-container-boxes div:active {
  border: 1px solid $violet;
}

.modal-terms-container-boxes svg {
  margin: 0rem;
  margin-bottom: $dimenS;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
}

.modal-terms-container-boxes h3,
.modal-ticketeritems-container h3 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: $black-1;
}

.modal-terms-container-boxes p,
.modal-ticketeritems-container p {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  color: $grey-navbar;
}

.modal-terms-container-boxes p span {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  color: $grey-navbar;
}

@media (max-width: $mediaLG) {
  .modal-terms-container {
    margin: $dimenS 0rem;
    padding: $dimenXXXL $dimenM $dimenXXL $dimenM;
    height: fit-content;
  }

  .modal-terms-container-welcome {
    margin-bottom: $dimenXXS;
    width: 100%;
    max-width: 580px;
    gap: $dimenL;
  }

  .modal-terms-container-welcome h2 {
    font-size: 19px;
    line-height: 25px;
  }

  .modal-terms-container-welcome p {
    font-size: 16px;
    line-height: 19px;
  }

  .modal-terms-container-welcome h4 {
    font-size: 16px;
    line-height: 19px;
  }

  .modal-terms-container-boxes {
    margin: 0rem;
    width: 100%;
    gap: $dimenS;
  }

  .modal-terms-container-boxes div {
    margin: 0rem;
    padding: $dimenS;
    width: 100%;
    max-width: 343px;
    height: 54px;
    border-radius: 14px;
    gap: $dimenS;
  }

  .modal-terms-container-boxes svg {
    display: none;
  }

  .modal-terms-container-boxes h3 {
    font-size: 16px;
    line-height: 22px;
  }

  @media (orientation: landscape) and (max-width: $mediaLG) and (max-height: 460px) {
    .modal-background {
      padding: $dimenS;
      align-items: start;
    }
  }
}

//MODAL TICKETER
.modal-ticketer-container {
  margin: $dimenS;
  padding: $dimenL;
  width: calc(100% - $dimenXXS - $dimenXXS);
  max-width: 1072px;
  height: fit-content;
  background: $white;
  border: 0px;
  border-radius: 20px;
  gap: $dimenL;
}

.modal-ticketer-container-top-container {
  margin: 0rem;
  width: 100%;
  gap: $dimenXS;
}

.modal-ticketer-container-top {
  margin: 0rem;
  width: 100%;
}

.modal-ticketer-container-bottom {
  margin: 0rem;
  width: 100%;
  gap: $dimenS;
}

.modal-ticketer-container-top-text > svg {
  transform: rotate(90deg);
  display: none;
}

.modal-ticketer-container-top-text > svg path {
  fill: $black-1;
}

.modal-ticketer-container-top h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: $black-1;
}

.modal-ticketer-container-top-container p {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  color: $grey-navbar;
}

.modal-ticketer-container-top > svg {
  flex-shrink: 0;
  margin: 0rem;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal-ticketer-container-top > svg path {
  fill: $grey-upload-image;
}

.modal-ticketer-container-bottom {
  width: 100%;
}

.modal-ticketer-item {
  margin: 0rem;
  padding: $dimenS $dimenM;
  width: 100%;
  background: $white-2;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 16px;
  gap: $dimenM;
}

.modal-ticketer-item-header {
  margin: 0rem;
  width: 100%;
  gap: $dimenS;
}

.modal-ticketer-item-header > h4 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: $grey-blue;
}

.modal-landing-terms {
  padding: $dimenXL $dimenXXXL;
  h4 {
    color: $grey-dark;
  }
}

.modal-ticketer-item-content {
  margin: 0rem;
  width: 100%;
}

.modal-ticketer-item-content h4,
.ticket-detail-info h4 {
  margin: 0rem;
  width: 100%;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $grey-navbar;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-ticketer-item-content h5,
.ticket-detail-info h5 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $black-1;
}

.modal-ticketer-item-content h6 {
  margin: 0rem;
  font-family: $font2;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
}

.modal-ticketer-item-content p {
  margin: 0rem;
  font-family: $font2;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: $grey-navbar;
}

#terms > div,
#terms-landing > div,
#policy > div {
  max-height: 230px;
  overflow-y: scroll;
}

.modal-ticketer-item-content-payment {
  margin: 0rem;
  width: 100%;
  height: 44px;
  gap: $dimenXS;
}

.modal-ticketer-item-content-payment div {
  flex-shrink: 0;
  margin: 0rem;
  padding: $dimenXXS;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 0px;
  background: $grey-mail-border;
}

.modal-ticketer-item-content-social-container {
  gap: $dimenXS;
  width: 416px;
}

.modal-ticketer-item-content-social {
  width: 200px;
}

.modal-ticketer-item-content-social-header {
  gap: $dimenXS;
}

.modal-ticketer-item-content-social-header svg {
  flex-shrink: 0;
  margin: 0rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.modal-ticketer-item-content-social-header h5 {
  margin: 0rem;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $black-1;
}

.modal-ticketer-item-content-social-text {
  height: 24px;
  width: 100%;
}

.modal-ticketer-item-content-picture {
  margin: 0rem;
  padding: $dimenXXXS $dimenXS $dimenXXXS $dimenXXXS;
  width: 310px;
  height: 77px;
  border: 2px solid $grey-modal-border;
  filter: drop-shadow(4px 4px 30px rgba(213, 213, 213, 0.25));
  border-radius: 12px;
  gap: $dimenXL;
}

.modal-ticketer-item-content-picture-left {
  margin: 0rem;
  width: 100%;
  gap: $dimenXL;
}

.modal-ticketer-item-content-picture-container {
  width: 100%;
  gap: $dimenL;
  row-gap: $dimenXXS;
}

.modal-ticketer-item-content-picture-item {
  flex-shrink: 0;
  margin: 0rem;
  padding: $dimenXXXS;
  width: 63px;
  height: 63px;
  background: $grey-blue-2;
  border-radius: 6px;
  svg {
    margin: 0rem;
    width: 24px;
    height: 24px;
    path {
      fill: $orange-sat;
    }
  }
}

.modal-ticketer-item-content-picture-item img {
  margin: 0rem;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}

.modal-ticketer-item-content-data {
  margin: 0px;
  width: calc(100% - 125px);
  gap: $dimenXXXS;
}

.modal-ticketer-item-content-data h5 {
  margin: 0px;
  width: 100%;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  color: $black-1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-ticketer-item-content-data h6 {
  margin: 0px;
  width: 100%;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-ticketer-item-content-info-container {
  max-width: 680px;
  gap: $dimenS;
}

.modal-ticketer-item-info {
  margin: 0rem;
  width: 232px;
  gap: $dimenXXXS;
}

.modal-ticketer-item .modal-ticketer-item-content .ticket-detail-field {
  width: 232px !important;
}

.modal-ticketer-item .modal-ticketer-item-content .ticket-detail-field-long {
  width: 100% !important;
}

.ticket-detail-field-long {
  max-width: 480px !important;
  height: 100%;
  max-height: 120px;
  h4 {
    white-space: normal !important;
    overflow-x: visible !important;
    overflow-y: scroll !important;
    text-overflow: inherit !important;
  }
}

.modal-ticketer-item-info-long {
  width: 332px !important;
  max-height: 120px;
}

.modal-ticketer-item-info-long h4,
.modal-ticketer-item-info-long-2 h4 {
  overflow-y: scroll;
}

.modal-ticketer-item-content-tickets-container {
  margin: 0rem;
  padding: 0rem;
  width: 100%;
  border: 1px solid $grey-event-line;
  border-radius: 16px;
  overflow: hidden;
  > div:first-child {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  > div:last-child {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  > div:nth-child(odd) {
    background: $grey-mail-border;
  }
}

.modal-ticketer-item-content-ticket {
  margin: 0rem;
  padding: $dimenXXXS $dimenXS;
  width: 100%;
  height: 32px;
  div {
    height: 20px;
  }
  h6 {
    margin: 0px;
    font-family: $font4;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $grey-navbar;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.modal-ticketer-item-content-ticket:first-child h6 {
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $black-1;
}

.modal-ticketer-item-content-ticket-left {
  flex-shrink: 1;
  max-width: calc(100% - 220px);
}

.modal-ticketer-item-content-ticket-right {
  gap: $dimenXXS;
  flex-shrink: 0;
  h6:nth-child(1) {
    width: 80px;
  }
  h6:nth-child(2),
  h6:nth-child(3) {
    width: 60px;
  }
}

@media (max-width: $mediaXS) {
  .modal-background-ticketer {
    background: $white;
  }

  .modal-background-footer {
    height: calc(100vh - 86px);
  }

  .modal-ticketer-container-top-text {
    cursor: pointer;
  }

  .modal-ticketer-container-top-text > svg {
    display: block;
  }

  .modal-ticketer-container {
    margin: 0rem;
    padding: $dimenXL $dimenS;
    width: 100%;
    height: inherit;
    min-height: 100%;
    border-radius: 0px;
    gap: $dimenXL;
  }

  .modal-ticketer-container-top > svg {
    display: none;
  }

  .modal-ticketer-container-top h3 {
    font-size: 22px;
  }

  .modal-ticketer-item {
    padding: $dimenM $dimenXS;
  }

  .modal-ticketer-item-header h4 {
    font-size: 18px;
  }

  .modal-ticketer-item-header button {
    max-width: 96px;
    max-height: 34px;
  }

  .modal-ticketer-item-content h6 {
    font-size: 12px;
  }

  .modal-ticketer-item-content-social-container {
    width: 200px;
  }

  .modal-ticketer-item-content-social {
    gap: $dimenXXS;
  }

  .modal-ticketer-item-content-picture {
    width: 100%;
  }

  .modal-ticketer-item-content-info-container {
    gap: $dimenXXS;
  }

  .modal-ticketer-item-info {
    width: 100%;
  }

  .modal-ticketer-item-content-ticket-left {
    flex-shrink: 1;
    max-width: calc(100% - 180px);
  }

  .modal-ticketer-item-content-ticket-right {
    gap: $dimenXXXS;
    h6:nth-child(1) {
      width: 60px;
    }
    h6:nth-child(2),
    h6:nth-child(3) {
      width: 52px;
    }
  }

  .modal-landing-terms {
    margin: $dimenS;
    padding: $dimenXXL $dimenM;
    max-width: calc(100vw - $dimenM);
    border-radius: 12px;
  }
}

//MODAL TICKETER SECTION
.modal-ticketeritems-container {
  position: relative;
  padding: $dimenXL $dimenXXXL;
  max-width: 740px;
  gap: $dimenXXXL;
}

.modal-ticketeritems-text {
  gap: $dimenS;
}

.modal-ticketeritems-buttons {
  width: 100%;
  gap: $dimenS;
}

.modal-ticketeritems-buttons button {
  border: 1px solid $purple-dark;
  background: $purple-light-2;
  color: $purple-dark;
}

.modal-ticketeritems-buttons button:last-child {
  border: 0px;
  background: $purple;
  color: $violet-dark;
}

@media (max-width: $mediaLG) {
  .modal-ticketeritems-container {
    position: fixed;
    bottom: 0px;
    margin: 0rem;
    padding: $dimenXL $dimenS $dimenL $dimenS;
    width: 100%;
    max-width: 100%;
    gap: $dimenXXXL;
    border-radius: 14px 14px 0px 0px;
  }
}

@media (max-width: $mediaXS) {
  .modal-ticketeritems-text h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

//MODAL DELETE

.modal-delete-container {
  position: relative;
  max-width: 508px;
}

.modal-cancel-container,
.modal-content-container {
  position: relative;
  padding: $dimenXL $dimenXXXL;
  width: 100%;
  max-width: 700px;
  height: auto;
  gap: $dimenXXXL;
  overflow: hidden;
}

.modal-cancel-container > svg {
  position: absolute;
  margin: 0px;
  bottom: 0px;
  left: 0px;
}

.modal-delete-container > svg:nth-child(1) {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  path {
    fill: $grey-upload-image;
  }
}

.modal-delete-container svg {
  margin: 0rem;
}

.modal-cancel-button-text {
  gap: $dimenS;
}

.modal-cancel-container h2 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: $grey-dark;
}

.modal-cancel-container p,
.modal-cancel-container li,
.modal-cancel-container ul {
  margin: 0rem;
  text-align: start;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $grey-dark;
}

.modal-cancel-container h4 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: $grey-dark;
}

.cancel-event-form {
  gap: calc($dimenXXXL + $dimenS);
}

.modal-cancel-container input {
  background: transparent;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black-1;
}

.modal-cancel-container input::placeholder {
  color: $grey-upload-image;
}

.cancel-event-form-input div {
  margin: 0rem;
  height: 24px;
}

.modal-cancel-button-container {
  width: 100%;
  gap: $dimenS;
  z-index: 1;
}

.modal-cancel-button-container button {
  margin: 0rem;
  padding: $dimenXXXS $dimenXXL;
  width: fit-content;
}

.modal-cancel-container button:nth-child(1) {
  background: $purple-light-2;
  border: 1px solid $purple-dark;
  color: $purple-dark;
}

.modal-cancel-container button:nth-child(2) {
  background: $purple;
  border: 0px;
  color: $violet-dark;
}

.modal-cancel-container button:nth-child(2):disabled {
  opacity: 0.2;
}

@media (max-width: $mediaXS) {
  .modal-ticketeritems-text h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .modal-cancel-button-container > button {
    width: 100%;
  }
}

//MODAL EVENT
.events-width {
  max-width: none;
  width: 100%;
}

.modal-ticketer-item-info-long-2 {
  width: 480px;
  max-height: 120px;
}

//LANDING

.modal-container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh);
}

.modal-landing-container {
  position: relative;
  padding: 7.5rem 12rem;
  max-width: calc(100% - $dimenL - $dimenL);
  height: 100%;
  background: $landing-background;
  background-image: linear-gradient(
    149.86deg,
    #272053 45.41%,
    rgba(39, 32, 83, 0.5) 81.63%
  );
  box-shadow: 0px 4px 40px rgba(68, 46, 203, 0.05);
  border-radius: 32px;
  gap: calc($dimenXXXL + $dimenXXXL);
  > svg {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
}

.modal-landing-top {
  max-width: 664px;
  gap: $dimenXS;
  h2 {
    margin: 0rem;
    text-align: center;
    font-family: $font4;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    color: $purple-light-2;
  }
  p {
    margin: 0rem;
    text-align: center;
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $purple-light-2;
  }
}

.modal-landing-container button {
  padding: $dimenXXS $dimenXL;
  background: $violet;
  box-shadow: 0px 2.1969px 2.1969px rgba(0, 0, 0, 0.02),
    0px 4.39381px 16.4768px rgba(0, 0, 0, 0.08),
    inset 0px 0px 16.4768px rgba(0, 0, 0, 0.05);
  border: 0px;
  border-radius: 24px;
  color: $purple-text;
  font-size: 16px;
  line-height: 31px;
}

@media (max-width: $mediaLG) {
  .modal-landing-container {
    padding: 5rem 8rem;
    border-radius: 24px;
    gap: $dimenXXXXL;
    > svg {
      top: 20px;
      right: 20px;
    }
  }

  .modal-landing-top {
    h2 {
      font-size: 56px;
      line-height: 57px;
    }
    p {
      font-size: 18px;
      line-height: 150%;
    }
  }
}

@media (max-width: $mediaXS) {
  .modal-landing-container {
    padding: $dimenXXL $dimenM;
    gap: $dimenXXXL;
    > svg {
      top: 16px;
      right: 16px;
    }
  }

  .modal-landing-top {
    h2 {
      font-size: 42px;
      line-height: 46px;
    }
    p {
      font-size: 14px;
      line-height: 150%;
    }
  }

  .modal-landing-container button {
    padding: $dimenXXS $dimenS;
  }
}

.modal-landing-pricing-container {
  position: relative;
  padding: $dimenXXL $dimenXXXL;
  max-width: calc(100% - $dimenL - $dimenL);
  height: 100%;
  background: $landing-background;
  background-image: linear-gradient(113.89deg, #272053 30.37%, #47435e 105.6%);
  box-shadow: 0px 4px 50px rgba(68, 46, 201, 0.2);
  border-radius: 24px;
  gap: $dimenXXL;
  > svg {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
}

.modal-landing-pricing-top {
  max-width: 760px;
  gap: $dimenXS;
  h2 {
    margin: 0rem;
    text-align: center;
    font-family: $font4;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    color: $purple-contact;
  }
  p {
    margin: 0rem;
    text-align: center;
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $purple-light-2;
  }
}

.modal-landing-pricing-container form {
  h4 {
    color: $purple-light-3;
  }
  input {
    border-color: $purple-light-3;
    color: $purple-light-3;
  }
  button {
    border: 0px;
    background: $purple-contact;
    color: $black-1;
  }
  button:disabled {
    opacity: 0.2;
  }
}

@media (max-width: $mediaLG) {
  .modal-landing-pricing-container {
    padding: $dimenL $dimenXXL;
    border-radius: 16px;
    gap: $dimenXL;
    > svg {
      top: 20px;
      right: 20px;
    }
  }

  .modal-landing-pricing-top {
    h2 {
      font-size: 37px;
      line-height: 38px;
    }
    p {
      font-size: 13px;
      line-height: 16px;
    }
  }
}

@media (max-width: $mediaXS) {
  .modal-landing-pricing-container {
    padding: $dimenXXL $dimenXL;
    gap: $dimenXL;
    > svg {
      top: 16px;
      right: 16px;
    }
  }

  .modal-landing-pricing-top {
    h2 {
      font-size: 26px;
      line-height: 35px;
    }
    p {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

//DISCOUNT

.modal-discount {
  gap: $dimenXXL;
}

.discount-form {
  width: 100%;
  gap: $dimenS;
}

.discount-field-container {
  width: calc(50% - $dimenXXS);
  gap: $dimenXS;
}

.discount-form h5 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $grey-navbar;
}

.discount-form .modal-cancel-button-container {
  margin-top: $dimenXXL;
}

//QR MODAL
#modal-qr-section {
  width: 100%;
  height: 100%;
  background: $white;
  img {
    margin: 0rem;
    width: 100%;
    max-width: 420px;
  }
  > svg {
    position: absolute;
    top: calc(50% - 250px);
    right: calc(50% - 250px);
    cursor: pointer;
    path {
      fill: $grey-navbar;
    }
  }
}

@media (max-width: $mediaXS) {
  #modal-qr-section {
    > svg {
      top: 20px;
      right: 20px;
    }
  }
}

//IMAGE MODAL

.crop-modal {
  padding: $dimenL calc($dimenL * 2);
  width: 100%;
  max-width: 540px;
  gap: $dimenL;
  .crop-modal-title {
    padding: $dimenXS $dimenXL;
    width: 100%;
    background: $grey-input;
    border-radius: 12px 12px 0px 0px;
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: $black-1;
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 60vh;
  }
  .crop-modal-buttons {
    width: 100%;
    gap: $dimenL;
    h3 {
      margin: 0rem;
      font-family: $font1;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 25px;
      text-decoration-line: underline;
      font-feature-settings: 'calt' off;
      color: $purple-dark;
      cursor: pointer;
    }
    button {
      height: 40px;
      font-size: 14px;
      line-height: 25px;
    }
    .hidden-canvas {
      display: none;
    }
  }
  .crop-modal-error {
    gap: $dimenS;
    > * {
      width: 100%;
    }
    > h3 {
      margin: 0rem;
      font-family: $font4;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: $black-1;
      text-align: center;
    }
    > p {
      margin: 0rem;
      font-family: $font3;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: $grey-dark;
      text-align: center;
    }
    > button {
      border: 0px;
      background: $purple-domain;
      color: $white;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      min-width: 262px;
    }
  }
}

@media (max-width: $mediaXS) {
  .crop-modal {
    padding: $dimenL $dimenS;
    max-width: none;
    .crop-modal-title {
      padding: $dimenXS $dimenXXS;
      text-align: center;
    }
    img {
      touch-action: auto;
    }
  }
}

//MODAL CONTENT

.modal-content-container {
  h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: $grey-dark;
  }
  button:disabled,
  button:disabled:hover,
  button:disabled:active {
    opacity: 0.5;
  }
}

@media (max-width: $mediaLG) {
  .modal-content-container {
    max-width: calc(100vw - $dimenXL);
  }
}

@media (max-width: $mediaXS) {
  .modal-content-container {
    max-width: 100vw;
  }
}

/* SUBSCRIPTION MODAL */

.subscription-modal-box {
  margin: 0rem;
  padding: $dimenXXL $dimenXXL;
  max-width: 700px;
  background: $grey-mail-box;
  border: 1px solid $grey-mail-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 20px;
  gap: $dimenS;
  position: relative;

  /* @media (max-width: $mediaXS) {
    border-radius: 0;
    gap: 2rem;
    margin: 0;
    min-height: 100%;
    padding: 2rem 1rem;
    width: 100%;
  } */

  .sub-close-modal {
    cursor: pointer;
    background-color: transparent;
    border: 0px;
    display: flex;
    justify-content: flex-end;
    svg path {
      fill: $black-1;
    }
  }

  .sub-title-modal {
    color: $black;
    font-family: $font1;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
  }

  .sub-modal-row {
    width: 100%;
    border-bottom: 1px solid $grey-mail-border;
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    p {
      color: $grey-navbar;
      font-family: $font2;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h4 {
      font-weight: bold;
      font-family: $font1;
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
    }

    &.total {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h5 {
        color: #000;
        font-family: $font2;
        font-size: 36px;
        font-style: normal;
        font-weight: bold;
        line-height: 46px;
      }

      h6 {
        color: $black-1;
        font-family: $font2;
        font-size: 17px;
        font-style: normal;
        font-weight: bold;
        line-height: 25.375px; /* 149.265% */
      }
      span {
        color: #000;
        font-family: $font2;
        font-size: 17px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
      }
    }
  }

  .plan-included {
    color: $grey-navbar;
    font-family: $font2;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  .plan-included-attributes {
    color: $blue-event-card;
    font-family: $font2;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.subscription-modal-box .support {
  max-width: 900px;
  width: 900px;
}

.subscription-modal-box > h3 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: $black-1;
}

.subscription-modal-box > p {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 350;
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: $grey-dark;
}

.subscription-modal-box > p > span {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: $grey-dark;
}

@media (max-width: $mediaXS) {
  .subscription-modal-box {
    margin: 0rem !important;
    padding: $dimenXXXL $dimenXL !important;
    background: $grey-mail-box !important;
    border: 0px !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}

.sub-modal-row.support {
  display: flex;
  gap: 12px;
  width: 100%;
  height: auto;
  padding: 24px 16px;
  align-items: center;
  border-radius: 8px;
  background: #efecfc;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  & .label {
    display: flex;
    width: 155px;
    height: 33px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 42px;
    background: #dad4ff;
    margin-bottom: 1rem;
  }

  & .support-title {
    color: #1c1c1c;
    font-family: $font3;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 120% */
  }

  & .support-link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #816cff;
    font-family: $font3;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    line-height: 25.375px; /* 149.265% */
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.sub-modal-row .buttonCancel {
  width: 232px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #442ecb;
  color: white;
  border: none;
}

.sub-footer-text {
  color: #646464;
  font-family: $font2;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.cancelModal.cancelModal {
  .changePlan-link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #816cff;
    font-family: $font2;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    line-height: 25.375px; /* 149.265% */
    text-decoration-line: underline;
    cursor: pointer;
  }
  .plan-included {
    color: #646464;
    font-family: $font2;
    font-size: 16px;
    font-style: normal;
    font-weight: 415;
    line-height: 150%; /* 24px */
  }

  .plan-included-attributes {
    color: #2a1b86;
    font-family: $font2;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  ul {
    list-style: none;
    padding: 0;
  }
  li {
    color: #646464;
    font-family: $font2;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */

    svg {
      margin-right: 0.5rem;
      width: 12px;
    }
  }
}

.sub-modal-row.isCompany {
  padding: 24px 0;
  & .support-title {
    color: #1c1c1c;
    font-family: $font3;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 120% */
  }
  & .changePlan-link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #816cff;
    font-family: $font2;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    line-height: 25.375px; /* 149.265% */
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.popup-paymentMethod a {
  color: white;
  margin: 0;
}

.popup-paymentMethod a:hover {
  color: white;
  margin: 0;
}

@import './styles/styles.scss';

*:focus {
  outline: none;
}

button:hover,
input[type='submit']:hover {
  opacity: 0.8;
}

button:active,
input[type='submit']:active {
  opacity: 0.6;
}

button:disabled:active,
input[type='submit']:disabled:active,
button:disabled:hover,
input[type='submit']:disabled:hover {
  opacity: 0.2;
}

.inner-container {
  width: 100%;
  //height: 100%;
  max-width: 1440px;
  margin: 0rem;
  //overflow: hidden;
}

@media (max-width: $mediaLG) {
  .inner-container {
    padding: 0rem;
  }
}

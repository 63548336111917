// FONTS
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap');
/*@font-face {
  font-family: "Sequel Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Sequel Sans"),
    url("../assets/SequelSans.ttf") format('truetype');
}
@font-face {
  font-family: "Sequel Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Sequel Sans"),
    url("../assets/SequelSansLight.ttf") format('truetype');
}*/
@font-face {
  font-family: 'Sequel Sans Book Head';
  src: local('Sequel Sans Book Head'), local('SequelSans-BookHead'),
      url('../assets/SequelSans-BookHead.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sequel Sans Light Body';
  src: local('Sequel Sans Light Body'), local('SequelSans-LightBody'),
      url('../assets/SequelSans-LightBody.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sequel Sans Light Disp';
  src: local('Sequel Sans Light Disp'), local('SequelSans-LightDisp'),
      url('../assets/SequelSans-LightDisp.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
$font1: 'Sequel Sans Book Head', sans-serif;
$font2: 'Sequel Sans Light Body', sans-serif;
$font3: 'Sequel Sans Light Disp', sans-serif;
$font4: 'Space Grotesk', sans-serif;

// COLORS
$landing-background: #0F0B30;
$violet: #442ECB;
$violet-dark: #3623AA;
$purple: #C8BFFF;
$purple-dark: #816CFF;
$purple-off: #E7E3FF;
$purple-text: #D4CDFF;
$purple-light: #F1EFFB;
$purple-light-2: #EFECFC;
$purple-light-3: #E3DEFC;
$purple-card: #DAD5FF;
$purple-pressed: #B2A6FF;
$purple-domain: #6B53FF;
$purple-contact: #C6BDFD;
$grey-light: #DEDEDE;
$grey-light-2: #EAEAEA;
$grey-light-3: #F4F4F4;
$grey-input: #F7F7F7;
$grey-dark: #484848;
$grey-mail-back: #838383;
$grey-mail-box: #FEFEFE;
$grey-mail-border: #F0F0F0;
$grey-navbar: #646464;
$grey-blue: #A5A9B8;
$grey-blue-1: #808390;
$grey-blue-2: #EFF0F2;
$grey-upload-image: #9A9FA5;
$grey-upload-image-line: #BCBCBC;
$grey-event-line: #E6E6E6;
$grey-event-background: #F9F9F9;
$grey-modal-border: #EBEBEB;
$grey-event-title: #636772;
$grey-event-icon: #C6CBD8;
$grey-landing-div: #DADADC;
$white: #FFFFFF;
$white-2: #FCFCFC;
$black: #0F0F0F;
$black-1: #1C1C1C;
$black-blue: #2A2C31;
$red-error: #EB0000;
$red-light: #FFE7E7;
$red-status: #E84B4B;
$green-ok: #27B973;
$green-background: #D4F1E3;
$green-2: #28B06F;
$green-3: #1ED860;
$success-fail-white: #FFF3F3;
$orange: #F27327;
$orange-border: #FF8C5A;
$orange-background: #FAE7DC;
$orange-sat: #FF5F00;
$blue-event-card: #2A1B86;
$orange-event-card: #73310A;
$orange-event-card-2: #AC4609;
$yellow: #FFC90C;

// MARGIN PADDING
$dimenXXXS: 0.25rem;
$dimenXXS: 0.5rem;
$dimenXS: 0.75rem;
$dimenS: 1rem;
$dimenM: 1.25rem;
$dimenL: 1.5rem;
$dimenXL: 2rem;
$dimenXXL: 2.5rem;
$dimenXXXL: 4rem;
$dimenXXXXL: 6rem;

//MEDIA QUERIES
$mediaXL: 1200px;
$mediaLG: 992px;
$mediaMD: 768px;
$mediaXS: 576px;

@import './styles/styles.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: $font1;
}

body {
  //max-width: 1440px; 
  margin: 0 auto !important; 
  float: none !important; 
  padding: 0;
  height: 100%;
  width: 100%;
  /*max-width: 1440px;
  /*overflow-y: hidden; /* Hide vertical scrollbar */
  //overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


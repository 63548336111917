@import 'styles.scss';

.main-register {
  margin: 0rem;
  padding: $dimenS;
  width: 100%;
  height: 100%;
  //min-width: 100vw;
  min-height: 100vh;
  background: $violet;
}

.main-register-container {
  height: 100%;
  //min-height: calc(100vh - 2rem);
  max-height: 749px;
}

.main-register-left,
.main-register-right {
  width: calc(50% - $dimenS);
  //height: 100%;
  min-height: calc(100vh - 2rem);
}

@media (max-width: $mediaLG) {
  .main-register {
    margin: 0rem;
    padding: 0rem;
  }

  .main-register-container {
    height: 100vh;
    max-height: none;
  }

  .main-register-right {
    width: 100%;
  }
}

// START REGISTER BUTTONS

.register-section {
  margin: 0rem;
  padding: $dimenXXXL;
  background: $white;
  width: 100%;
  max-width: 684px;
  height: calc(100vh - 2rem);
  max-height: 749px;
  min-height: 569px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  gap: $dimenS;
}

.register-section h3 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: $black-1;
}

.register-email-form,
.change-user-data {
  width: 348px;
  gap: $dimenXXS;
  position: relative;
}

.register-email-form h5 {
  width: 100%;
  margin: 0rem;
  margin-bottom: $dimenXXXS;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $grey-dark;
}

.register-email-form > svg {
  margin: 0rem;
  position: absolute;
  top: 48px;
  right: 14px;
}

.register-email-form-email,
.ticketer-field,
.MuiTextField-root {
  margin: 0rem;
  //margin-bottom: $dimenXXS;
  width: 100%;
  padding: $dimenXS;
  height: 52px;
  background: $white;
  border: 0.75px solid $grey-light;
  border-radius: 15px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $black-1;
}

.ticketer-field:disabled {
  background-color: #f6f6f6;
  cursor: not-allowed;
}

/* .text-disabled h4,
p,
label {
  color: #adadad !important;
} */

.ticketer-field-padding {
  padding-left: calc($dimenXXL + $dimenXXS);
}

.info-inside-input-container {
  position: relative;
}

.info-inside-input,
.info-inside-input-percentaje {
  position: absolute;
  top: 74px;
  left: $dimenXS;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $black-1;
}

.info-inside-input-percentaje {
  top: 42px;
  left: $dimenM;
}

.MuiTextField-root {
  border: 0.75px solid $grey-light !important;
}

.MuiTextField-root * {
  font-family: $font1 !important;
}

.MuiTextField-root > * {
  max-height: 52px;
  border-radius: 15px !important;
  border: 0px !important;
}

.MuiPickersPopper-root * {
  font-family: $font1 !important;
  text-transform: capitalize !important;
}

.MuiPickersPopper-root .MuiPickersDay-root:focus {
  background-color: $purple-light !important;
}

.MuiTextField-root input {
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $black-1;
}

.MuiPickersPopper-root .Mui-selected,
.MuiPickersPopper-root .Mui-selected:focus {
  background-color: $violet !important;
}

.MuiButton-textPrimary {
  color: $violet !important;
}

.MuiMultiSectionDigitalClock-root {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
}
.MuiMultiSectionDigitalClock-root::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

.MuiPickersPopper-root .MuiPickersDay-root:hover,
.MuiPickersPopper-root .MuiMenuItem-gutters:hover,
.MuiPickersPopper-root .MuiButton-textPrimary:hover {
  background-color: $purple-light-3 !important;
}

.MuiTextField-root fieldset {
  border: 0px !important;
}

.purple-border,
.datepicker-touched > div:nth-child(2) {
  border: 0.75px solid $violet !important;
}

.red-border,
.datepicker-error > div:nth-child(2) {
  border: 0.75px solid $red-error !important;
}

.register-email-form-email-error,
.ticketer-field-error {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $red-error;
}

.checkbox-register-container,
.checkbox-percentage {
  margin-bottom: $dimenL;
  gap: $dimenS;
  position: relative;
}

/* Hide the browser's default checkbox */
.checkbox-register-container input,
.checkbox-percentage input {
  position: absolute;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
  z-index: 1;
}

/* Create a custom checkbox */
.checkmark {
  margin: 0rem;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid $purple;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  z-index: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-register-container input:hover ~ .checkmark,
.checkbox-percentage input:hover ~ .checkmark {
  background-color: rgba($purple, 0.6);
}

/* When the checkbox is checked, add a blue background */
.checkbox-register-container input:checked ~ .checkmark,
.checkbox-percentage input:checked ~ .checkmark {
  background-color: $purple;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-register-container input:checked ~ .checkmark:after,
.checkbox-percentage input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-register-container .checkmark:after,
.checkbox-percentage .checkmark:after {
  width: 5px;
  height: 10px;
  border: solid $violet-dark;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-register-container label,
.checkbox-percentage label {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $grey-dark;
}

.checkbox-register-container span,
.checkbox-percentage span {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $purple-dark;
  text-decoration: underline;
  cursor: pointer;
}

.register-section hr {
  margin: $dimenXXS 0px;
  width: 100%;
  max-width: 324px;
  height: 1px;
  overflow: visible; /* For IE */
  padding: 0;
  border: 1px solid $grey-light-2;
  color: rgba($grey-dark, 0.2);
  text-align: center;
}

.register-section hr::after {
  content: 'O';
  display: inline-block;
  position: relative;
  top: -0.6em;
  padding: 0px $dimenS;
  background: $white;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: rgba($grey-dark, 0.2);
}

.register-email-form-button {
  margin: 0rem;
  padding: $dimenXXS;
  width: 100%;
  max-width: 348px;
  height: 52px;
  background: $purple;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  border: 0px;
  border-radius: 14px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: $violet-dark;
}

.register-email-form-button:disabled {
  background: $purple-off;
  color: white;
}

.spinner-container {
  width: 100%;
  height: 100vh;
}

.spinner-border-purple {
  margin: 0px !important;
  width: 20px !important;
  height: 20px !important;
  --bs-spinner-border-width: 0.1em !important;
  color: $violet-dark !important;
}

.register-ok-button {
  background: $green-ok;
  color: $white;
}

.register-ok-button path {
  fill: $white;
}

.sign-mail {
  margin: 0rem;
  padding: $dimenXL;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba($grey-mail-back, 0.05);
}

.sign-mail-box {
  margin: 0rem;
  padding: $dimenXXXL $dimenXXXXL;
  width: 568px;
  height: 502px;
  background: $grey-mail-box;
  border: 1px solid $grey-mail-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 20px;
  gap: $dimenS;
}

.sign-mail-box > svg {
  margin-bottom: 54px;
}

.sign-mail-box > h3 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: $black-1;
}

.sign-mail-box > p {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 350;
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: $grey-dark;
}

.sign-mail-box > p > span {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: $grey-dark;
}

@media (max-width: $mediaLG) {
  .register-section {
    border-radius: 0px;
    max-width: 100vw;
    height: 100vh;
    max-height: none;
    padding: $dimenXXXL $dimenS;
  }

  .register-section h3 {
    font-size: 26px;
    line-height: 55px;
  }

  .register-email-form {
    width: 100%;
    max-width: 343px;
  }

  .register-section hr {
    width: 60%;
    max-width: 324px;
  }
}

@media (max-width: $mediaXS) {
  .sign-mail {
    padding: 0rem;
  }

  .sign-mail-box {
    margin: 0rem !important;
    padding: $dimenXXXL $dimenXL !important;
    width: 100vw !important;
    height: 100vh !important;
    background: $grey-mail-box !important;
    border: 0px !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }
}

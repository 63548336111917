@import 'styles.scss';

*:focus {
  outline: none;
}

.navbar-button {
  padding: $dimenXXS $dimenXXL;
  background: transparent;
  border: 1px solid $purple-light-3;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.4));
  border-radius: 21px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  font-feature-settings: 'calt' off;
  color: $purple-light-2;
}

@media (max-width: $mediaLG) {
  .navbar-button {
    padding: $dimenXXXS $dimenL;
    border-radius: 17.5px;
    font-size: 14px;
  }
}

.register-button {
  width: 100%;
  height: 52px;
  max-width: 348px;
  padding: $dimenXS $dimenL;
  gap: $dimenXXS;
  background: $grey-input;
  border: 1px solid $grey-light;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 14px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: $grey-dark;
}

.register-button > svg {
  margin: 0rem;
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
}

.g_id_signin,
.fb-login-button {
  display: none;
}

.user-picture {
  text-transform: uppercase;
  margin: 0rem;
  padding: 0rem;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $violet-dark;
  overflow: hidden;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 10.7563px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $purple-text;
  img {
    width: 100%;
    height: 100%;
  }
}

.profile-button {
  height: 48px;
  max-width: 348px;
  padding: $dimenXS $dimenL;
  gap: $dimenXXS;
  background: $violet;
  border: 0px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  font-feature-settings: 'calt' off;
  text-align: center;
  color: $purple-text;
}

.profile-button-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 22ch;
}

.profile-button > svg {
  margin: 0rem;
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
}

.profile-button > div {
  color: $purple-text !important;
}

.profile-button > svg path {
  fill: $purple-text;
}

.profile-button:disabled {
  opacity: 0.2;
}

@media (max-width: $mediaLG) {
  .register-button {
    max-width: 343px;
  }

  .profile-button {
    font-size: 14px;
  }
}

//SUCCESS FAIL
.success-fail-popup,
.success-fail-popup-large {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: $dimenM $dimenL;
  padding: $dimenS;
  height: 56px;
  width: 436px;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 0px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $success-fail-white;
  z-index: 5;
}

.contact-landing-form-container > .success-fail-popup {
  top: 80px;
}

.success-fail-popup-large {
  width: calc(100vw - $dimenL - $dimenL - $dimenXS);
  max-width: none !important;
}

.success-fail-popup-green {
  background: $green-ok;
}

.success-fail-popup-red {
  background: $red-error;
}

.success-fail-popup-right {
  cursor: pointer;
}

@media (max-width: $mediaLG) {
  .success-fail-popup {
    position: fixed;
    top: auto;
    bottom: 86px;
    right: 50%;
    transform: translateX(50%);
    margin: $dimenXL 0rem;
    padding: $dimenXS;
    height: 52px;
    width: calc(100vw - $dimenXL);
    max-width: 436px;
    font-size: 12px;
  }
}

//EVENT BUTTON
.event-button {
  margin: 0rem;
  height: 32px;
  padding: $dimenXXXS $dimenXXXS $dimenXXXS $dimenXS;
  background: $purple-light-2;
  border: 0px;
  border-radius: 16px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $violet;
  gap: $dimenXXXS;
}

.event-button svg {
  margin: 0rem;
  width: 24px;
  height: 24px;
  transform: rotate(270deg);
}

@media (max-width: $mediaXS) {
  .event-button {
    padding: $dimenXXXS;
    font-size: 0px;
    gap: 0px;
  }
}

//PREVIEW BUTTON
.preview-button {
  display: flex;
  height: 48px;
  max-width: 348px;
  padding: $dimenXS $dimenL;
  gap: $dimenXXS;
  background: transparent;
  border: 1px solid $purple-dark;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  font-feature-settings: 'calt' off;
  text-align: center;
  color: $purple-dark;
}

.preview-button:disabled {
  border: 0px;
  background: $grey-light;
  color: $white;
}

.preview-button > svg {
  display: none;
}

@media (max-width: $mediaLG) {
  .preview-button {
    padding: $dimenXXS;
    width: 48px;
    font-size: 0px;
    gap: 0rem;
  }

  .preview-button > svg {
    display: block;
    margin: 0rem;
    width: 31px;
    height: 31px;
    min-height: 31px;
    min-width: 31px;
  }

  .preview-button > svg path {
    fill: $purple-dark;
  }

  .preview-button:disabled > svg path {
    fill: $white;
  }
}

@media (max-width: 305px) {
  .preview-button {
    display: none;
  }
}

//TICKETER ITEM
.ticketer-section-item {
  flex-grow: 1;
  position: relative;
  margin: 0rem;
  padding: $dimenM $dimenL;
  height: 188px;
  width: calc(50% - $dimenS);
  min-width: 380px;
  background: $white-2;
  box-shadow: 0px 4px 50px rgba(204, 204, 204, 0.1);
  border-radius: 18px;
}

.ticketer-section-item-required {
  background: $purple-card;
}

.ticketer-section-item-required-success {
  background: $purple-dark;
  box-shadow: 3px 4px 10px #cdc5ff;
}

.ticketer-section-item-success {
  border: 1px solid $violet;
  box-shadow: 3px 4px 10px rgba(156, 156, 156, 0.25);
}

.ticketer-section-item-status {
  flex-shrink: 0;
  padding: $dimenXXS;
  width: 42px;
  height: 42px;
  background: $orange-background;
  border: 0.6px solid $orange-border;
  border-radius: 50%;
}

.ticketer-success {
  background: $green-background;
  border: 0.6px solid $green-ok;
}

.ticketer-section-item-title {
  gap: $dimenS;
}

.ticketer-section-item h2 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: $purple-dark;
}

.ticketer-section-item h4 {
  margin: 0rem;
  max-width: 75%;
  font-family: $font2;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: $purple-dark;
}

.ticketer-section-item-required-success h2,
.ticketer-section-item-required-success h4 {
  color: $grey-mail-box;
}

.ticketer-button {
  margin: 0rem;
  width: 190px;
  height: 40px;
  background: $purple-light-3;
  border: 0.55px solid $violet;
  border-radius: 20px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: $violet;
  z-index: 1;
}

.ticketer-section-item > svg {
  position: absolute;
  width: 113px;
  height: 95px;
  bottom: 0px;
  right: 0px;
  border-bottom-right-radius: 18px;
}

@media (max-width: $mediaLG) {
  .ticketer-section-item {
    padding: $dimenM;
    height: 174px;
  }

  .ticketer-section-item > svg {
    width: 89px;
    height: 71px;
  }

  .ticketer-section-item-status {
    padding: $dimenXXXS;
    width: 32px;
    height: 32px;
  }

  .ticketer-section-item-status svg {
    width: 16px;
    height: 16px;
  }

  .ticketer-section-item h2 {
    font-size: 20px;
  }

  .ticketer-section-item h4 {
    font-size: 14px;
  }

  .ticketer-button {
    width: 180px;
    height: 38px;
    line-height: 24px;
  }
}

@media (max-width: $mediaMD) {
  .ticketer-section-item {
    width: 100%;
    min-width: auto;
    //max-width: 380px;
  }
}

@media (max-width: 356px) {
  .ticketer-section-item h4 {
    display: none;
  }

  .ticketer-button {
    width: 160px;
  }
}

// TERMS POLICY BUTTON
.terms-policy-button {
  margin: 0rem;
  padding: $dimenXXS $dimenXXL;
  height: 48px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  font-feature-settings: 'calt' off;
  gap: $dimenXXS;

  &.disabled {
    background-color: #eaeaea;
    box-shadow: none;
  }

  &.disabled:hover {
    opacity: 1;
  }
}

@media (max-width: $mediaLG) {
  .terms-policy-button {
    width: 100%;
    max-width: 432px;
    height: 52px;
  }
}

//EVENT STATUS
.event-status {
  margin: 0rem;
  padding: $dimenXXXS $dimenXXS;
  width: 120px;
  height: 32px;
  border: 0px;
  border-radius: 10px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  background: $grey-light-2;
  color: $grey-blue-1;
}

.event-state-color1 {
  background: $orange-background;
  color: $orange;
}

.event-state-color2 {
  background: $green-background;
  color: $green-ok;
}

.event-state-color4 {
  background: $red-light;
  color: $red-status;
}

//TICKET ITEM
.ticket-item {
  align-self: flex-start;
  margin: 0rem;
  padding: $dimenM;
  width: 308px;
  background: $white-2;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px 0px rgba(212, 212, 212, 0.25);
  border-radius: 20px;
  gap: $dimenM;
  transition: 0.8 ease-in;
}

.ticket-hover:hover {
  box-shadow: 0px 0px 30px rgba(143, 143, 143, 0.339);
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.8 ease-in;
}

.ticket-active:active {
  transition: 0.3 ease-in;
  border: 1px solid $violet;
}

.ticket-clickable {
  cursor: pointer;
}

.ticket-clickable:hover {
  opacity: 0.8;
}

.ticket-clickable:active {
  opacity: 0.6;
}

.ticket-clickable:has(button:hover) {
  opacity: 1;
}

.ticket-clickable:has(button:active) {
  opacity: 1;
}

.ticket-item-banner {
  position: relative;
  width: 268px;
  height: 182px;
  border-radius: 12px;
  overflow: hidden;
}

.ticket-item-banner-info {
  position: absolute;
  top: 16px;
  left: 12px;
  margin: 0rem;
  padding: $dimenXXS $dimenXS;
  background: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  gap: $dimenXXXS;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: $white;
}

.ticket-item-banner-info > div {
  margin: 0rem;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.green-dot {
  background: $green-2;
}

.yellow-dot {
  background: $yellow;
}

.ticket-item-banner > svg,
.ticket-item-banner > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ticket-item-text {
  gap: $dimenS;
}

.ticket-item-separator {
  width: 100%;
  opacity: 0.1;
  border: 1px solid #000000;
}

.ticket-item-type {
  gap: $dimenXXS;
}

.ticket-item-type h2 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: $black-blue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticket-item-type h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: $violet;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: $black-blue;
  }
}

.ticket-item-type h4 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: $violet;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticket-item-extra,
.ticket-item-location {
  gap: $dimenXS;
}

.box-text-item {
  margin: 0rem;
  padding: $dimenXXXS $dimenXS;
  width: 100%;
  gap: $dimenXXXS;
  background: $grey-light-3;
  border-radius: 10px;
  svg {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
  }
}

.ticket-item-location-top {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: $black-blue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  path {
    fill: $black-blue;
  }
}

.ticket-item-location-bottom {
  margin: 0rem;
  gap: $dimenL;
  > div {
    gap: $dimenXXXS;
  }
  p {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  p:first-child {
    color: $black;
    opacity: 0.4;
  }
  p:last-child {
    color: $black-blue;
  }
}

.ticket-item-extra-top {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: $black-blue;
}

.ticket-item-extra-bottom {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: $grey-blue-1;
}

.ticket-item-extra-top-container {
  width: 100%;
  gap: calc($dimenXXXS / 2 + 1px);
}

.ticket-item-sale {
  margin: 0rem;
  gap: $dimenS;
}

.ticket-item-sale button {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0rem;
  width: 58px;
  height: 48px;
  background: $purple;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02), 0px 4px 15px rgba(0, 0, 0, 0.08),
    inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  border: 0px;
  border-radius: 14px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $violet-dark;
}

.ticket-item-sale > div {
  flex-shrink: 1;
  margin: 0rem;
  height: 48px;
  width: 100%;
  background: $grey-light-3;
  border-radius: 14px;
  border: 0px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $violet-dark;
}

.ticket-orange-color {
  flex-shrink: 1;
  flex-grow: 1;
  padding: $dimenXXXS $dimenXXS;
  width: fit-content;
  max-width: 70px;
  font-weight: 700;
  background: $orange-background;
  color: $orange;
  gap: calc($dimenXXXS / 2);
  path {
    fill: $orange-sat;
  }
}

.ticket-item > button,
.ticket-sale-detail > button {
  border: 0px;
  background: $purple;
  color: $violet-dark;
}

.ticket-item > button:disabled,
.ticket-sale-detail > button:disabled {
  border: 0px;
  background: $grey-light;
  color: $white;
}

.ticket-item > button:disabled:hover,
.ticket-item > button:disabled:active,
.ticket-sale-detail > button:disabled:hover,
.ticket-sale-detail > button:disabled:active {
  opacity: 1;
}

.ticket-item-info {
  margin: 0rem;
  padding: $dimenXS $dimenL;
  gap: $dimenXXXS;
  background: $grey-light-3;
  border-radius: 10px;
  p {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $grey-navbar;
    width: fit-content;
  }
  p:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    flex-shrink: 0;
  }
}

@media (max-width: $mediaLG) {
  .ticket-item-sale button {
    height: 52px;
  }

  .ticket-item-sale > div {
    height: 52px;
  }
}

//UPLOAD TICKET
.upload-ticket {
  margin: 0rem;
  padding: $dimenXXXS $dimenXS;
  width: 100%;
  min-height: 54px;
  background: transparent;
  border: 1px dashed $grey-blue;
  border-radius: 10px;
  gap: $dimenXXS;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  font-feature-settings: 'calt' off;
  color: $grey-navbar;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  > input {
    display: none;
  }
  > p {
    margin: 0rem;
    padding: 0rem;
    width: fit-content;
    font-family: $font2;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    font-feature-settings: 'calt' off;
    color: $grey-navbar;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.upload-ticket:hover {
  opacity: 0.8;
}

.upload-ticket:active {
  opacity: 0.6;
}

//CHECKOUT TICKET
.checkout-tickets,
.checkout-final,
.checkout-discount-code,
.checkout-main-form,
.checkout-resume {
  margin: 0rem;
  padding: $dimenM;
  width: 100%;
  gap: $dimenS;
  background: $white;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 16px;
  > * {
    width: 100%;
  }
  > *:last-child {
    h4 {
      color: $purple-domain;
      cursor: pointer;
    }
    h4:hover {
      opacity: 0.8;
    }
    h4:active {
      opacity: 0.6;
    }
  }
  .checkout-tickets-separator {
    height: 1px;
    background: $black;
    opacity: 0.1;
  }
  h3 {
    margin: 0rem;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: $black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h4 {
    margin: 0rem;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: $grey-blue;
  }
  .checkout-tickets-top-left {
    gap: $dimenXXXS;
  }
  .checkout-tickets-top-right {
    gap: $dimenXXXS;
    > div {
      gap: $dimenXXXS;
      > div {
        width: 32px;
        height: 32px;
        border-radius: 10px;
        font-size: 12px;
        line-height: 14px;
      }
    }
    button {
      width: 32px;
      height: 32px;
      border-radius: 10px;
      font-size: 12px;
      line-height: 14px;
    }
    h3 {
      text-align: end;
    }
  }
}

.checkout-tickets-bottom {
  gap: $dimenXXXS;
  svg {
    flex-shrink: 0;
    margin: 0rem;
    width: 16px;
    height: 16px;
    path {
      fill: $grey-blue;
    }
  }
}

.checkout-final {
  h4 {
    font-size: 14px;
    line-height: 16px;
    span {
      color: $black-blue;
      opacity: 1;
    }
  }
}

.checkout-discount-code {
  h4 {
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $grey-dark;
  }
  button {
    border: 0px;
    background: $purple;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02),
      0px 4px 15px rgba(0, 0, 0, 0.08), inset 0px 0px 15px rgba(0, 0, 0, 0.05);
    color: $violet-dark;
    cursor: pointer;
  }
  button:disabled {
    border: 0px;
    background: $grey-light;
    color: $white;
    cursor: default;
  }
  button:disabled:active,
  button:disabled:hover {
    opacity: 1;
  }
}

//TICKET OPENED
.ticket-opened-sale-detail {
  margin: 0rem;
  padding: 0rem;
  gap: $dimenXL;
  > * {
    width: 100%;
  }
}

// BUTTON SWITCH
.button-switch {
  background-color: #cdc5ff;
  width: auto;
  display: flex;
  border-radius: 100px;

  button {
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    font-family: $font2;
    letter-spacing: 0.833px;
    color: $violet;
    background-color: $purple;
    padding: 12px 16px;
    border: 0px;
    border-radius: 100px;
    height: 44px;
    width: 111px;
    text-transform: uppercase;

    span {
      height: 100%;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  button.active {
    border-radius: 100px;
    color: $purple;
    background-color: $violet;
    box-shadow: 0px 5px 7px 0px rgba(82, 67, 194, 0.23);
  }

  button:not(.active) {
    border-radius: 100px;
    background-color: $purple;
    color: #000000;
  }
}

// NOMRAL BUTTON

.tibet-button {
  width: fit-content;
  display: flex;
  height: 52px;
  padding: 16px 46px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 14px;
  background: $purple-dark;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05) inset, 0px 4px 15px 0px rgba(0, 0, 0, 0.08), 0px 2px 2px 0px rgba(0, 0, 0, 0.02); */
  border: 0px;
  color: #fff;
  font-family: $font2;
  font-size: 17px;
  font-weight: 700;
  line-height: 130%; /* 22.1px */

  &.fullwidth {
    width: 100%;
  }

  &:disabled {
    background: #e7e3ff;
    color: $white;
  }

  &:hover:disabled {
    background: #e7e3ff;
    opacity: 1;
  }
  &:hover:enabled {
    background: $purple-dark;
  }
}

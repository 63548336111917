@import 'styles.scss';

.navbar-container {
  position: fixed;
  top: 0px;
  width: 100%;
  padding: $dimenM $dimenXXXXL;
  background: rgba(30, 24, 73, 0.6);
  backdrop-filter: blur(40px);
  gap: $dimenS;
  z-index: 4;
  > svg {
    flex-shrink: 0;
    width: 200px;
    height: 43px;
  }
}

.landing-main {
  padding-top: 83.6px;
  width: 100%;
  background: $landing-background;
  background-image: linear-gradient(
      145.46deg,
      rgba(178, 166, 255, 0.29) -4.57%,
      rgba(68, 46, 203, 0) 52.92%
    ),
    radial-gradient(
      farthest-corner at 95% 70%,
      rgba(178, 166, 255, 0.29) -4.57%,
      rgba(68, 46, 203, 0) 20%
    );
  //background-image: linear-gradient(145.46deg, rgba(178, 166, 255, 0.29) -4.57%, rgba(68, 46, 203, 0) 52.92%), linear-gradient(-34.54deg, rgba(178, 166, 255, 0.29) -4.57%, rgba(68, 46, 203, 0) 52.92%);
}

.faq-main {
  background-image: linear-gradient(
      145.46deg,
      rgba(178, 166, 255, 0.29) -4.57%,
      rgba(68, 46, 203, 0) 52.92%
    ),
    linear-gradient(
      -34.54deg,
      rgba(178, 166, 255, 0.29) -4.57%,
      rgba(68, 46, 203, 0) 52.92%
    );
}

.main-landing-container {
  position: relative;
  width: 100%;
  padding: 164px $dimenXXXXL;
  gap: $dimenXXL;
}

.main-landing-container > img:nth-child(1) {
  position: absolute;
  bottom: -12px;
}

.main-landing-container > img:nth-child(2) {
  position: absolute;
  top: 72px;
  left: calc(50% - 500px);
}

.main-landing-container > img:nth-child(3) {
  position: absolute;
  top: 260px;
  left: calc(50% - 580px);
}

.main-landing-container > img:nth-child(4) {
  position: absolute;
  top: 84px;
  right: calc(50% - 560px);
}

.main-landing-container > img:nth-child(5) {
  position: absolute;
  top: 408px;
  right: calc(50% - 420px);
}

.main-landing-container > img:nth-child(6) {
  position: absolute;
  top: 348px;
  right: calc(50% - 488px);
}

.main-landing-container > * {
  max-width: 700px;
}

.main-landing-text {
  width: 100%;
  gap: $dimenL;
}

.main-landing-text > h1 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 300;
  font-size: 95px;
  line-height: 90px;
  text-align: center;
  letter-spacing: -0.03em;
  font-feature-settings: 'calt' off;
  color: $purple-light-2;
  span {
    font-weight: 500;
  }
}

.main-landing-text > p {
  margin: 0rem;
  max-width: 560px;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: $purple-light-2;
}

.main-landing-container > button {
  margin: 0rem;
  min-width: 175px;
  background-color: $purple-domain;
  color: $purple-light-2;
}

.video-landing-container {
  position: relative;
  width: 100%;
  padding: 100px $dimenXXXXL;
  gap: $dimenXXL;
}

.video-landing-container h2 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: $purple-pressed;
}

.video-landing-item-container {
  position: relative;
  padding-top: 56.25%;
  width: 95%;
  border-radius: 18px;
  box-shadow: 0px 4px 60px rgba(68, 46, 203, 0.25);
  z-index: 1;
}

.video-landing-item-container video,
.video-landing-item-container iframe,
.video-landing-item-container img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}

.video-landing-container > svg {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.footer-landing-section {
  width: 100%;
  padding: $dimenM $dimenXXXXL;
  padding-bottom: $dimenXL;
  gap: $dimenS;
  > svg {
    flex-shrink: 0;
    width: 97px;
    height: 43px;
    path {
      fill: $purple-light-2;
    }
  }
}

.footer-landing-section-top {
  width: 100%;
  gap: $dimenS;
}

.footer-landing-section-left {
  gap: $dimenM;
  > svg {
    flex-shrink: 0;
    width: 144px;
    height: 31px;
  }
}

.footer-landing-section-right {
  gap: $dimenS;
}

.footer-landing-section-left-icons {
  gap: $dimenXXS;
}

.footer-landing-section-left-icons svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.footer-landing-section-left-icons svg:hover {
  opacity: 0.8;
}

.footer-landing-section-left-icons svg:active {
  opacity: 0.6;
}

.footer-landing-section-bottom h6 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $purple-light-2;
}

.section-title-landing-container {
  width: 100%;
  gap: $dimenS;
}

.section-title-landing-container p {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: $purple-dark;
}

.section-title-landing-container h2 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  text-align: center;
  color: $purple-light-2;
}

.section-title-landing-container h6 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $white-2;
}

.benefits-landing-container,
.metrics-landing-container,
.pricing-landing-container {
  width: 100%;
  padding: calc($dimenXXXL + $dimenS) $dimenXXXXL;
  gap: calc($dimenXL + $dimenL);
}

.benefits-landing-cards-container {
  width: 100%;
  gap: $dimenS;
}

.benefits-landing-card {
  position: relative;
  padding: $dimenXXXL $dimenXXL;
  width: calc(50% - $dimenXXS);
  height: 440px;
  background: linear-gradient(
    149.86deg,
    #272053 45.41%,
    rgba(39, 32, 83, 0.47) 81.63%
  );
  box-shadow: 0px 4px 40px rgba(68, 46, 203, 0.05);
  border-radius: 32px;
  overflow: hidden;
  gap: $dimenXL;
}

.benefits-landing-card:nth-child(1) .benefits-image-container {
  position: absolute;
  bottom: 0px;
  right: 60px;
}

.benefits-landing-card:nth-child(2) .benefits-image-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.benefits-landing-card:nth-child(3) .benefits-image-container {
  position: absolute;
  bottom: 0px;
  right: 20px;
}

.benefits-landing-card:nth-child(1):hover
  .benefits-image-container
  > img:nth-child(1) {
  bottom: 0px;
  right: 80px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(1):hover
  .benefits-image-container
  > img:nth-child(2) {
  bottom: 0px;
  right: -20px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(1) .benefits-image-container img:nth-child(1) {
  position: absolute;
  bottom: -20px;
  right: 60px;
  z-index: 1;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(1) .benefits-image-container img:nth-child(2) {
  position: absolute;
  bottom: -20px;
  right: 0px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(2):hover
  .benefits-image-container
  > img:nth-child(1) {
  bottom: 20px;
  right: 40px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(2):hover
  .benefits-image-container
  > img:nth-child(2) {
  bottom: 0px;
  right: 0px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(2):hover
  .benefits-image-container
  > img:nth-child(3) {
  bottom: 180px;
  right: 5px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(2) .benefits-image-container img:nth-child(1) {
  position: absolute;
  bottom: 40px;
  right: 60px;
  z-index: 2;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(2) .benefits-image-container img:nth-child(2) {
  position: absolute;
  bottom: -20px;
  right: 0px;
  z-index: 1;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(2) .benefits-image-container img:nth-child(3) {
  position: absolute;
  bottom: 160px;
  right: -15px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(3):hover
  .benefits-image-container
  > img:nth-child(1) {
  bottom: 30px;
  right: 40px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(3):hover
  .benefits-image-container
  > img:nth-child(2) {
  bottom: 20px;
  right: 20px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(3):hover
  .benefits-image-container
  > img:nth-child(3) {
  bottom: 160px;
  right: 0px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(3) .benefits-image-container img:nth-child(1) {
  position: absolute;
  bottom: 40px;
  right: 60px;
  z-index: 2;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(3) .benefits-image-container img:nth-child(2) {
  position: absolute;
  bottom: -20px;
  right: 0px;
  transition: 300ms linear;
}

.benefits-landing-card:nth-child(3) .benefits-image-container img:nth-child(3) {
  position: absolute;
  bottom: 140px;
  right: 20px;
  z-index: 1;
  transition: 300ms linear;
}

.benefits-landing-card button {
  max-width: 210px;
  z-index: 3;
}

.benefits-landing-card-top {
  width: 100%;
  max-width: 520px;
  gap: $dimenS;
  z-index: 3;
}

.benefits-landing-card:nth-child(1) .benefits-landing-card-top {
  width: 51%;
}

.benefits-landing-card-top h3 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 43px;
  letter-spacing: -0.02em;
  color: $purple-light-2;
}

.benefits-landing-card-top p {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $purple-light-2;
}

.benefits-landing-card:nth-child(1) {
  width: 100%;
  > svg {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.benefits-landing-card:nth-child(2) {
  > svg {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

.benefits-landing-card:nth-child(3) {
  > svg {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

.metrics-landing-cards-container {
  width: 100%;
  gap: $dimenXL;
}

.metrics-landing-counter-separator {
  width: 2px;
  height: 140px;
  background: $purple-dark;
}

.metrics-landing-counter {
  align-self: stretch;
  width: calc(33% - $dimenXXL - $dimenXXXS);
}

.metrics-landing-counter h3 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 400;
  font-size: 95px;
  line-height: 112px;
  text-align: center;
  color: $purple-pressed;
}

.metrics-landing-counter p {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $purple-dark;
}

.pricing-landing-cards-container {
  gap: $dimenS;
}

.pricing-landing-cards-container-scroll {
  width: 100%;
  overflow: auto;
}

.pricing-landing-card {
  width: 100%;
  align-self: stretch;
  max-width: 300px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: linear-gradient(
    149.86deg,
    #272053 45.41%,
    rgba(39, 32, 83, 0.47) 81.63%
  );
  border: 0px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  gap: $dimenXL;
  overflow: hidden;
  > * {
    width: 100%;
  }
}

#pricing-landing-section.animate .pricing-landing-card:nth-child(1) {
  animation: opacityAttributes 2.5s linear;
}

#pricing-landing-section.animate .pricing-landing-card:nth-child(2) {
  animation: opacityAttributes 2s linear;
}

#pricing-landing-section.animate .pricing-landing-card:nth-child(3) {
  animation: opacityAttributes 1.5s linear;
}

@keyframes opacityAttributes {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.pricing-landing-card-top {
  padding: $dimenXL $dimenXXL 0rem $dimenXXL;
  gap: $dimenXL;
}

.pricing-landing-card-bottom {
  height: 100%;
  padding: 0rem $dimenXXL calc($dimenXXL + $dimenS) $dimenXXL;
  gap: calc($dimenL + $dimenL);
}

.ticket-cut {
  width: 100%;
  border: 1px dashed #0f0b30;
}

.pricing-landing-card-top > h2 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 39px;
  text-align: center;
  color: $purple-light-2;
}

.pricing-landing-card-top > button {
  min-width: 128px;
  border: 1px solid $purple-dark;
  background: transparent;
  color: $purple-dark;
  cursor: default;
  opacity: 1 !important;
}

.pricing-landing-card-top > button:hover,
.pricing-landing-card-top > button:active {
  opacity: 1 !important;
}

.pricing-landing-card-bottom > button {
  min-width: 220px;
  background: $purple;
  color: $blue-event-card;
  border: 0px;
}

.pricing-landing-card-text {
  width: 100%;
  gap: $dimenXXXS;
}

.pricing-landing-card-text-line {
  width: 100%;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $purple-light-2;
  gap: $dimenXS;
  svg {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    path {
      fill: $purple-dark;
    }
  }
}

.contact-landing-container {
  position: relative;
  width: 100%;
  padding: calc($dimenXXXL + $dimenS) $dimenXXXXL;
  > svg {
    position: absolute;
    top: -58px;
    right: 52px;
  }
}

.contact-landing-img-container {
  align-self: stretch;
  width: 45%;
  max-width: 500px;
  margin: 0rem;
  padding: 0rem;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  z-index: 1;
  overflow: hidden;
}

.contact-landing-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-landing-form-container {
  align-self: stretch;
  padding: calc($dimenXXL + $dimenS);
  width: 55%;
  background: $purple;
  border: 0px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  overflow: hidden;
  gap: calc($dimenXXL + $dimenS);
  background-repeat: no-repeat;
  background-size: 500px calc(100% + 1px), contain;
  background-position-x: right;
  z-index: 1;
}

.contact-width {
  width: 100%;
  border-radius: 24px;
}

.contact-landing-form-container-top {
  gap: $dimenXXS;
}

.contact-landing-form-container-top h2 {
  margin: 0rem;
  font-family: $font4;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
}

.contact-landing-form-container-top h2:nth-child(1) {
  color: $black;
}

.contact-landing-form-container-top h2:nth-child(2) {
  color: $violet;
}

.contact-landing-form-wrap {
  width: 100%;
  gap: $dimenXS;
}

.contact-landing-form {
  width: 100%;
  column-gap: $dimenXXL;
  row-gap: $dimenXS;
}

.contact-landing-form-item {
  width: calc(50% - $dimenM);
  gap: $dimenXXS;
  h4 {
    margin: 0rem;
    font-family: $font2;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $black;
  }
  input {
    margin: 0rem;
    background: transparent;
    border: 0px;
    border-bottom: 1px solid $black;
    font-family: $font2;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $black;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    transition-delay: 99999s;
  }
}

.contact-landing-form-item-error {
  margin: 0rem;
  min-height: 24px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: $red-error;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-landing-form-item:last-child {
  width: 100%;
}

.contact-landing-container button {
  max-width: 148px;
  height: 41px;
  background: $black;
  color: $purple-light;
  border: 0px;
  font-size: 14px;
  line-height: 21px;
}

.contact-landing-container button:disabled {
  opacity: 0.2;
}

.faq-landing-container {
  position: relative;
  width: 100%;
  padding: calc($dimenXXL + $dimenXXL) $dimenXXXXL;
  gap: calc($dimenXXXXL + $dimenS);
}

.faq-landing-items {
  width: 100%;
  gap: $dimenS;
}

.faq-landing-item {
  padding: $dimenM $dimenL;
  background: linear-gradient(
    254.24deg,
    rgba(129, 108, 255, 0.1) 40.56%,
    rgba(129, 108, 255, 0.097) 85.91%
  );
  border: 0.8px solid transparent;
  border-radius: 24px;
  gap: 0rem;
  cursor: pointer;
  overflow: hidden;
  transition: border 300ms ease-out;
  h3 {
    margin: 0rem;
    font-family: $font4;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: $purple-light-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    margin: 0rem;
    max-width: 768px;
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 0px;
    color: $purple-light-2;
    opacity: 0;
    transition: 300ms linear;
  }
  svg {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    transform: rotate(-90deg);
    transition: 300ms linear;
    path {
      fill: $purple-light-2;
    }
  }
}

.faq-landing-item-active {
  background: rgba(129, 108, 255, 0.1);
  border: 0.8px solid $purple-dark;
  gap: $dimenS;
  p {
    opacity: 1;
    line-height: 150%;
    transition: 300ms linear;
  }
  transition: border 300ms ease-in, gap 300ms linear;
}

@media (max-width: 1080px) {
  .main-landing-container > img:nth-child(2) {
    left: calc(50% - 460px);
  }

  .main-landing-container > img:nth-child(3) {
    left: calc(50% - 540px);
  }

  .main-landing-container > img:nth-child(4) {
    right: calc(50% - 520px);
  }

  .main-landing-container > img:nth-child(5) {
    right: calc(50% - 380px);
  }

  .main-landing-container > img:nth-child(6) {
    right: calc(50% - 448px);
  }
}

@media (max-width: $mediaLG) {
  .navbar-container {
    padding: $dimenL;
    svg {
      width: 162px;
      height: 35px;
    }
  }

  .footer-landing-section {
    padding: $dimenL;
  }

  .main-landing-container {
    padding: 128px $dimenXXXL;
    gap: $dimenS;
  }

  .main-landing-container > img:nth-child(1) {
    width: 224px;
    height: 112px;
    bottom: 0px;
  }

  .main-landing-container > img:nth-child(2) {
    width: 105px;
    height: 26px;
    top: 84px;
    left: calc(50% - 308px);
  }

  .main-landing-container > img:nth-child(3) {
    width: 116px;
    height: 132px;
    top: 212px;
    left: calc(50% - 332px);
  }

  .main-landing-container > img:nth-child(4) {
    width: 120px;
    height: 90px;
    top: 100px;
    right: calc(50% - 320px);
  }

  .main-landing-container > img:nth-child(5) {
    width: 41px;
    height: 41px;
    top: 300px;
    right: calc(50% - 248px);
  }

  .main-landing-container > img:nth-child(6) {
    width: 78px;
    height: 78px;
    top: 260px;
    right: calc(50% - 300px);
  }

  .main-landing-container > * {
    max-width: 396px;
  }

  .main-landing-text {
    gap: $dimenS;
  }

  .main-landing-text > h1 {
    font-size: 48px;
    line-height: 51px;
  }

  .main-landing-text > p {
    max-width: none;
    font-size: 14px;
    line-height: 17px;
  }

  .main-landing-container > button {
    font-size: 16px;
  }

  .video-landing-container {
    width: 100%;
    padding: 100px $dimenXXXL;
    gap: $dimenL;
  }

  .video-landing-container h2 {
    font-size: 20px;
    line-height: 20px;
  }

  .video-landing-container video,
  .video-landing-container iframe {
    border-radius: 10px;
    box-shadow: 0px 2.2471px 33.7065px rgba(68, 46, 203, 0.25);
  }

  .video-landing-container > svg {
    bottom: 20px;
    left: 0px;
  }

  .section-title-landing-container {
    gap: $dimenXXS;
  }

  .section-title-landing-container p {
    font-size: 14px;
    line-height: 16px;
  }

  .section-title-landing-container h2 {
    font-size: 32px;
    line-height: 48px;
  }

  .benefits-landing-container,
  .metrics-landing-container,
  .pricing-landing-container {
    padding: calc($dimenXXL + $dimenM) $dimenL;
    gap: calc($dimenXL + $dimenS);
  }

  .benefits-landing-card {
    padding: $dimenXXL;
    width: 100%;
  }

  .benefits-landing-card:nth-child(1) .benefits-image-container,
  .benefits-landing-card:nth-child(2) .benefits-image-container,
  .benefits-landing-card:nth-child(3) .benefits-image-container {
    right: 40px;
  }

  .benefits-landing-card:nth-child(1) .benefits-image-container img {
    max-width: 200px;
  }

  .benefits-landing-card button {
    max-width: 180px;
  }

  .benefits-landing-card-top {
    max-width: 492px;
    gap: $dimenXS;
  }

  .metrics-landing-counter-separator {
    height: 80px;
  }

  .metrics-landing-counter h3 {
    font-size: 60px;
    line-height: 76px;
  }

  .metrics-landing-counter p {
    font-size: 14px;
    line-height: 16px;
  }

  .pricing-landing-cards-container {
    gap: $dimenXXS;
  }

  .pricing-landing-card {
    max-width: 200px;
    border-radius: 14px;
    gap: $dimenS;
  }

  .pricing-landing-card-top {
    padding: $dimenM $dimenM 0rem $dimenM;
    gap: $dimenS;
  }

  .benefits-landing-card:nth-child(1) .benefits-landing-card-top {
    width: 100%;
  }

  .pricing-landing-card-bottom {
    padding: 0rem $dimenM $dimenXL $dimenM;
    gap: calc($dimenL);
  }

  .pricing-landing-card-top > h2 {
    font-size: 24px;
    line-height: 26px;
  }

  .pricing-landing-card-top > button {
    height: 20px;
    min-width: 74px;
    font-size: 10px;
    line-height: 12px;
  }

  .pricing-landing-card-bottom > button {
    height: 21px;
    min-width: 124px;
    font-size: 10px;
    line-height: 12px;
  }

  .pricing-landing-card-text {
    gap: calc($dimenXXXS / 2);
  }

  .pricing-landing-card-text-line {
    font-size: 10px;
    gap: $dimenXXXS;
    svg {
      width: 8px;
      height: 8px;
    }
  }

  .contact-landing-container {
    padding: calc($dimenXXL + $dimenM) $dimenL;
    > svg {
      display: none;
    }
  }

  .contact-landing-img-container {
    display: none;
  }

  .contact-landing-form-container {
    padding: $dimenXL $dimenXXXL;
    width: 100%;
    border-radius: 18px;
    gap: $dimenXXL;
    background-image: none !important;
  }

  .contact-landing-form-container-top {
    gap: $dimenXXS;
  }

  .contact-landing-form-container-top h2 {
    font-size: 36px;
    line-height: 43px;
    text-align: center;
  }

  .contact-landing-form-wrap {
    width: 100%;
    gap: $dimenXS;
  }

  .contact-landing-form {
    row-gap: $dimenXXXS;
  }

  .contact-landing-form-item {
    gap: $dimenXXXS;
    h4 {
      font-size: 12px;
      line-height: 16px;
    }
    input {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .contact-landing-form-item-error {
    min-height: 16px;
    font-size: 12px;
    line-height: 16px;
  }

  .contact-landing-container button {
    height: 35px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 10px;
  }

  .faq-landing-container {
    padding: $dimenXXL $dimenM;
    gap: $dimenXXXXL;
  }

  .faq-landing-items {
    gap: $dimenXS;
  }

  .faq-landing-item {
    padding: $dimenS;
    border-radius: 16px;
    h3 {
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .faq-landing-item-active {
    gap: $dimenXS;
  }
}

@media (max-width: 680px) {
  .main-landing-container > img:nth-child(2) {
    left: calc(50% - 268px);
  }

  .main-landing-container > img:nth-child(3) {
    left: calc(50% - 292px);
  }

  .main-landing-container > img:nth-child(4) {
    right: calc(50% - 280px);
  }

  .main-landing-container > img:nth-child(5) {
    right: calc(50% - 208px);
  }

  .main-landing-container > img:nth-child(6) {
    right: calc(50% - 260px);
  }
}

@media (max-width: $mediaXS) {
  .navbar-container {
    padding: $dimenS $dimenL;
    gap: $dimenXXXS;
    svg {
      width: 118px;
      height: 25px;
    }
  }

  .landing-main {
    padding-top: 67.6px;
  }

  .main-landing-container {
    padding: 160px $dimenS;
    gap: $dimenXL;
  }

  .main-landing-container > img,
  .main-landing-container > button {
    transform: none !important;
  }

  .main-landing-container > img:nth-child(1) {
    width: 300px;
    height: 140px;
    bottom: 0px;
  }

  .main-landing-container > img:nth-child(2) {
    width: 138px;
    height: 34px;
    top: 72px;
    left: calc(50% - 69px);
  }

  .main-landing-container > img:nth-child(3),
  .main-landing-container > img:nth-child(4),
  .main-landing-container > img:nth-child(5),
  .main-landing-container > img:nth-child(6) {
    display: none;
  }

  .main-landing-container > * {
    max-width: auto;
  }

  .main-landing-text > h1 {
    font-size: 42px;
    line-height: 44px;
  }

  .main-landing-text > p {
    font-size: 14px;
    line-height: 20px;
  }

  .video-landing-container {
    width: 100%;
    padding: 68px $dimenS;
    gap: $dimenXL;
  }

  .video-landing-container h2 {
    font-size: 16px;
    line-height: 29px;
  }

  .video-landing-container video,
  .video-landing-container iframe {
    border-radius: 6px;
    box-shadow: 0px 1.24914px 18.737px rgba(68, 46, 203, 0.25);
  }

  .video-landing-container > svg {
    display: none;
  }

  .footer-landing-section {
    gap: $dimenS;
  }

  .footer-landing-section {
    gap: $dimenS;
  }

  .footer-landing-section-top {
    gap: $dimenS;
  }

  .footer-landing-section-left,
  .footer-landing-section-right {
    gap: $dimenXS;
  }

  .footer-landing-section-left {
    > svg {
      width: 130px;
      height: 28px;
    }
  }

  .section-title-landing-container h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .section-title-landing-container h6 {
    font-size: 14px;
    line-height: 20px;
  }

  .benefits-landing-container,
  .metrics-landing-container,
  .pricing-landing-container {
    padding: calc($dimenXL + $dimenXS) $dimenS;
    gap: $dimenXXXL;
  }

  .benefits-landing-card {
    padding: $dimenXL $dimenM;
    height: 380px;
    gap: $dimenS;
  }

  .benefits-landing-card:nth-child(1) .benefits-image-container,
  .benefits-landing-card:nth-child(2) .benefits-image-container,
  .benefits-landing-card:nth-child(3) .benefits-image-container {
    right: 0px;
  }

  .benefits-landing-card:nth-child(1) .benefits-image-container img {
    max-width: 148px;
  }

  .benefits-landing-card:nth-child(2)
    .benefits-image-container
    img:nth-child(1) {
    max-width: 156px;
  }

  .benefits-landing-card:nth-child(2)
    .benefits-image-container
    img:nth-child(2) {
    max-width: 208px;
  }

  .benefits-landing-card:nth-child(2)
    .benefits-image-container
    img:nth-child(3) {
    max-width: 116px;
  }

  .benefits-landing-card button {
    max-width: 136px;
    font-size: 13px;
    line-height: 16px;
  }

  .benefits-landing-card-top {
    width: 100%;
    max-width: auto;
    gap: $dimenXXS;
  }

  .benefits-landing-card-top h3 {
    font-size: 22px;
    line-height: 26px;
  }

  .benefits-landing-card-top p {
    font-size: 14px;
    line-height: 18px;
  }

  .benefits-landing-card:nth-child(1) {
    > svg {
      top: -120px;
      right: -120px;
    }
  }

  .benefits-landing-card:nth-child(2) {
    > svg {
      left: -120px;
    }
  }

  .benefits-landing-card:nth-child(3) {
    > svg {
      right: -120px;
    }
  }

  .metrics-landing-counter-separator {
    width: 120px;
    height: 2px;
  }

  .metrics-landing-counter {
    align-self: auto;
    width: 75%;
  }

  .pricing-landing-cards-container {
    gap: $dimenL;
  }

  .pricing-landing-card {
    flex-shrink: 0;
    max-width: 292px;
    border-radius: 24px;
    gap: $dimenXL;
  }

  .pricing-landing-card-top {
    padding: $dimenXL $dimenXL 0rem $dimenXL;
    gap: $dimenXL;
  }

  .pricing-landing-card-bottom {
    padding: 0rem $dimenXL $dimenXXXL $dimenXL;
    gap: calc($dimenL + $dimenL);
  }

  .pricing-landing-card-top > h2 {
    font-size: 42px;
    line-height: 44px;
  }

  .pricing-landing-card-top > button {
    height: 35px;
    min-width: 128px;
    font-size: 16px;
    line-height: 18px;
  }

  .pricing-landing-card-bottom > button {
    height: 37px;
    min-width: 221px;
    font-size: 16px;
    line-height: 18px;
  }

  .pricing-landing-card-text {
    gap: $dimenXXXS;
  }

  .pricing-landing-card-text-line {
    font-size: 14px;
    gap: $dimenXXS;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  #pricing-landing-section.animate .pricing-landing-card:nth-child(1),
  #pricing-landing-section.animate .pricing-landing-card:nth-child(2),
  #pricing-landing-section.animate .pricing-landing-card:nth-child(3) {
    animation: none;
  }

  .contact-landing-container {
    padding: calc($dimenS + $dimenXL) $dimenXS;
  }

  .contact-landing-form-container {
    padding: $dimenXL;
    border-radius: 24px;
    gap: $dimenXL;
  }

  .contact-landing-form-container-top {
    gap: $dimenXXS;
  }

  .contact-landing-form-container-top h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .contact-landing-form-wrap {
    width: 100%;
    gap: $dimenXS;
  }

  .contact-landing-form {
    width: 100%;
    column-gap: $dimenXXL;
    row-gap: $dimenXXXS;
  }

  .contact-landing-form-item {
    width: 100%;
    gap: $dimenXXXS;
    h4 {
      font-size: 14px;
      line-height: 16px;
    }
    input {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .contact-landing-form-item-error {
    min-height: 16px;
    font-size: 14px;
    line-height: 16px;
  }

  .contact-landing-container button {
    height: 35px;
    width: 100%;
    max-width: none;
    font-size: 14px;
    line-height: 16px;
  }

  .faq-landing-container {
    padding: calc($dimenXL + $dimenL) $dimenM;
    gap: calc($dimenXXL + $dimenXXL);
  }

  .faq-landing-items {
    gap: $dimenM;
  }

  .faq-landing-item {
    padding: $dimenM $dimenL;
    border-radius: 24px;
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .faq-landing-item-active {
    gap: $dimenL;
  }
}

//ELVIS TICKETER

.home-elvis-main,
.landing-event-main {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  background: $white-2;
}

.home-elvis-main-banner button,
.landing-event-banner button {
  position: absolute;
  top: 42px;
  right: 42px;
  z-index: 1;
}

.home-elvis-main-banner,
.landing-event-banner {
  position: relative;
  width: 100%;
  height: 204px;
  overflow: hidden;
}

.landing-event-banner {
  height: 480px;
}

.home-elvis-main-banner > img,
.landing-event-banner > img {
  width: 100%;
  min-width: 360px;
  background-size: auto;
  min-height: 200px;
}

.home-elvis-main-banner > svg,
.landing-event-banner > svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.home-elvis-main-data {
  position: relative;
  top: -132px;
  margin: 0em;
  margin-bottom: -132px;
  padding: $dimenXL $dimenXXL;
  width: 100%;
  height: 100%;
  min-height: 500px;
  gap: calc(3 * $dimenS);
}

.home-elvis-main-data-left {
  align-self: stretch;
  width: calc(34% - $dimenL);
}

.home-elvis-main-data-right {
  align-self: stretch;
  gap: $dimenL;
  width: calc(66% - $dimenL);
}

.home-elvis-main-data-right h3 {
  margin: 0rem;
  margin-top: 132px;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: $black-1;
}

.home-elvis-main-data-right > div {
  background: $white;
  height: 100%;
}

.home-elvis-main-data-left {
  margin: 0rem;
  padding: $dimenXXL $dimenXL;
  gap: $dimenXXL;
  background: $white;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 16px;
  max-height: 700px;
}

.home-elvis-main-data-left > svg,
.home-elvis-main-data-left > img {
  object-fit: cover;
  margin: 0rem;
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.home-elvis-main-data-left h2 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: $black-1;
}

.home-elvis-main-data-left p {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $grey-navbar;
  span {
    margin-left: $dimenXXS;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}

.home-elvis-main-footer {
  margin: 0rem;
  padding: $dimenXL $dimenXXXXL;
  width: 100%;
  min-height: 161px;
  background: $black;
  gap: $dimenXXS;
}

.home-elvis-main-footer-left {
  gap: $dimenS;
}

.home-elvis-main-footer-left h3 {
  margin: 0rem;
  font-family: $font2;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  color: $grey-blue-1;
}

.home-elvis-main-footer-left > div {
  margin: 0rem;
  height: 43px;
  gap: $dimenS;
}

.home-elvis-main-footer-left > div > a {
  width: 43px;
  height: 43px;
  text-decoration: none;
  svg {
    flex-shrink: 0;
    margin: 0rem;
    height: 43px;
    width: 43px;
    cursor: pointer;
    circle {
      fill: $black-blue;
    }
    path {
      fill: $grey-blue;
    }
  }
}

.home-elvis-main-footer-right h4 {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: $grey-blue-1;
  cursor: pointer;
}

.home-elvis-main-footer-right h4:hover {
  opacity: 0.8;
}

.home-elvis-main-footer-right h4:active {
  opacity: 0.6;
}

.landing-event-body {
  padding: 3.25rem 5rem;
  gap: $dimenXXXL;
}

.landing-event-description {
  gap: $dimenXXL;
}

.landing-event-description p {
  margin: 0rem;
  font-family: $font3;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $grey-dark;
}

.landing-event-bar {
  gap: $dimenS;
}

.landing-event-bar h2 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: $black-blue;
}

.landing-event-bar svg {
  flex-shrink: 0;
  margin: 0rem;
  width: 40px;
  height: 40px;
  circle {
    fill: $purple-light-3;
  }
  path {
    fill: $violet;
  }
  cursor: pointer;
}

.landing-event-bar-left {
  gap: $dimenXS;
  svg {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    path {
      fill: $black-blue;
    }
  }
}

.landing-event-bar-right {
  gap: $dimenXS;
}

.landing-event-metrics-container {
  max-width: calc(100vw - 5rem);
  overflow: auto;
  white-space: nowrap;
}
.card-stats-shadow {
  border-radius: 200px;
  border: 0.55px solid $grey-modal-border;
  box-shadow: 0px 4px 50px 0px rgba(204, 204, 204, 0.4);
}
.landing-event-metrics {
  margin: 0rem;
  padding: $dimenXL $dimenXXXL;
  min-width: 1270px;
  background: $white-2;
  border-radius: 200px;
  gap: $dimenS;
}

.detail-event-separator {
  margin: 0rem;
  padding: 0rem;
  height: 100%;
  width: 2px;
  background: $grey-landing-div;
}

.landing-event-metrics .detail-event-info-data-item-text-top {
  gap: $dimenS;
  height: fit-content;
  min-height: auto;
}

.landing-event-metrics .detail-event-info-data-item-text-top h5 {
  line-height: 24px;
}

.landing-event-metrics .detail-event-info-data-item-text-top svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.landing-event-tickets-container {
  width: 100%;
  gap: $dimenS;
}

.landing-event-tickets {
  gap: $dimenXL;
}

.landing-event-tickets h3 {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  color: $black-blue;
}

.sale-resume {
  padding: $dimenM $dimenS;
  gap: $dimenXS;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: none !important;
  height: 65px;
  background: $purple-domain;
  box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.05);
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: $white;
  z-index: 3;
  cursor: pointer;
}

.sale-resume > div {
  padding: 0rem;
  width: 25px;
  height: 25px;
  background: $violet;
  border-radius: 50%;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $grey-modal-border;
}

//TICKET DETAIL LANDING

%ticket-sale-detail-main-title {
  margin: 0rem;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $black-blue;
}

.ticket-sale-detail-main,
.checkout-main {
  margin: 0rem;
  padding: $dimenXL $dimenS;
  gap: $dimenXL;
}

.ticket-sale-detail-main.padding-bottom {
  padding-bottom: $dimenXXXXL;
}

.ticket-sale-detail-main > *,
.checkout-main > * {
  width: 100%;
  max-width: 420px;
}

.ticket-sale-detail-title {
  gap: $dimenXXS;
  overflow: hidden;
  cursor: pointer;
  svg {
    margin: 0rem;
    path {
      fill: $black-1;
    }
  }
  h3 {
    margin: 0rem;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: $black-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.ticket-sale-detail-form {
  gap: $dimenL;
}

.ticket-sale-detail-main .home-section-events-horizontal {
  min-width: auto;
  width: 100%;
}

.ticket-opened-sale-top {
  gap: $dimenM;
  > * {
    width: 100%;
  }
}

.ticket-sale-detail-info {
  gap: $dimenXXS;
}

.ticket-sale-detail-info > *,
.ticket-sale-detail-sale,
.ticket-sale-detail-info-location {
  margin: 0rem;
  padding: $dimenM $dimenS;
  width: 100%;
  background: $white;
  border: 1px solid $grey-modal-border;
  box-shadow: 4px 4px 30px rgba(213, 213, 213, 0.25);
  border-radius: 16px;
  gap: 0px;
  transition: 300ms linear;
  p {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 0px;
    line-height: 0px;
    letter-spacing: -0.01em;
    color: $black-1;
    opacity: 0;
    transition: 300ms linear;
    width: 100%;
  }
  svg {
    transform: rotate(180deg) !important;
    transition: 300ms linear;
  }
}

.ticket-sale-detail-sale {
  padding: $dimenXXS $dimenS;
  gap: $dimenXXXS;
  font-family: $font3;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: $grey-navbar;
  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    path {
      fill: $purple-dark;
    }
  }
  span {
    color: $purple-dark;
  }
}

.ticket-sale-detail-sale > div {
  margin: 0rem;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.ticket-sale-detail-info-bar {
  width: 100%;
  h3 {
    @extend %ticket-sale-detail-main-title;
  }
  svg {
    cursor: pointer;
  }
}

.ticket-sale-detail-info-bottom {
  margin: 0rem;
  width: 100%;
  height: 0px;
  transition: 300ms linear;
  overflow: hidden;
}

.ticket-sale-detail-info-bottom-children {
  width: 100%;
  gap: $dimenXXXS;
  h5 {
    margin: 0rem;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $black;
    opacity: 0;
    transition: 300ms linear;
  }
  h4 {
    margin: 0rem;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: $black-blue;
    opacity: 0;
    transition: 300ms linear;
  }
}

.ticket-sale-detail-info-history-bottom {
  width: 100%;
  gap: $dimenXXXS;
  overflow: hidden;
}

.ticket-sale-detail-info-history-bottom-children {
  gap: 0rem;
  h6 {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 0px;
    line-height: 0px;
    opacity: 0;
    transition: 300ms linear;
  }
}

.ticket-sale-detail-info-history-bottom-children:first-child {
  h6 {
    color: $black;
  }
}

.ticket-sale-detail-info-history-bottom-children:last-child {
  h6 {
    color: $black-blue;
    text-align: end;
  }
}

.history-separator {
  margin: 0rem;
  width: 100%;
  height: 0px;
  background: $black;
  opacity: 0;
  transition: 300ms linear;
}

.ticket-sale-detail-info > *.active,
.ticket-sale-detail-info-location.active {
  gap: $dimenS;
  transition: 300ms linear;
  p {
    font-size: 16px;
    line-height: 24px;
    opacity: 1;
    transition: 300ms linear;
  }
  svg {
    transform: rotate(0deg) !important;
    transition: 300ms linear;
  }
  .ticket-sale-detail-info-bottom {
    height: 100px;
    transition: 300ms linear;
    h4 {
      opacity: 1;
      transition: 300ms linear;
    }
    h5 {
      opacity: 0.4;
      transition: 300ms linear;
    }
  }
  .ticket-sale-detail-info-history-bottom {
    gap: $dimenXXXS;
    .ticket-sale-detail-info-history-bottom-children {
      gap: $dimenXXXS;
      transition: 300ms linear;
      h6 {
        font-size: 14px;
        line-height: 16px;
        transition: 300ms linear;
        span {
          color: $violet-dark;
          margin-right: $dimenXXS;
        }
      }
    }
    .ticket-sale-detail-info-history-bottom-children:first-child {
      h6 {
        opacity: 0.4;
        transition: 300ms linear;
      }
    }
    .ticket-sale-detail-info-history-bottom-children:last-child {
      h6 {
        opacity: 1;
        transition: 300ms linear;
      }
    }
  }
  .history-separator {
    height: 1px;
    opacity: 0.1;
    transition: 300ms linear;
  }
}

.ticket-sale-detail-content,
.ticket-sale-detail-mail {
  gap: $dimenXS;
  > * {
    width: 100% !important;
    max-width: none;
  }
  h3 {
    @extend %ticket-sale-detail-main-title;
  }
  h4 {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: $black-1;
  }
}

.ticket-sale-detail-content,
.radius-circle {
  border-radius: 50% !important;
}

.my-ticket-detail .ticket-sale-detail-mail > div,
.my-ticket-detail .ticket-sale-detail-mail > div:hover,
.my-ticket-detail .ticket-sale-detail-mail > div:active {
  opacity: 1;
  cursor: default;
}

.ticket-sale-detail {
  gap: $dimenS;
  > * {
    width: 100%;
  }
  > button {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02),
      0px 4px 15px rgba(0, 0, 0, 0.08), inset 0px 0px 15px rgba(0, 0, 0, 0.05);
  }
  > p {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $purple-domain;
    text-align: center;
  }
}

.ticket-sale-detail-buttons {
  width: 100%;
  gap: $dimenXS;
  > button {
    width: 100% !important;
    max-width: none;
    border: 0px;
  }
  > button:first-child {
    background: $violet;
    color: $grey-modal-border;
  }
  > button:last-child {
    background: $purple;
    color: $violet;
  }
}

.checkout-main-progress {
  position: relative;
  width: 100%;
}

.checkout-main-progress-line {
  position: absolute;
  top: 19px;
  left: calc($dimenS);
  width: calc(100% - $dimenXL);
  height: 2px;
  background: $purple-pressed;
}

.checkout-main-progress-div {
  max-width: 52px;
  gap: $dimenXXS;
  z-index: 1;
  p {
    margin: 0rem;
    font-family: $font4;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    color: $purple-pressed;
  }
}

.checkout-main-progress-circle {
  padding: $dimenXXXS;
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid $purple-pressed;
  border-radius: 50%;
  font-family: $font1;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $purple-pressed;
}

.checkout-main-progress-div.circle-active {
  p {
    color: $violet;
  }
  .checkout-main-progress-circle {
    background: $violet;
    border: 0px;
    color: $grey-modal-border;
  }
}

.checkout-main-container {
  width: 100%;
  gap: $dimenL;
  > * {
    width: 100%;
    > h3 {
      width: 100%;
      margin: 0rem;
      font-family: $font1;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 16px;
      color: $violet;
    }
  }
  > button {
    border: 0px;
    background: $purple-domain;
    color: $white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
  .checkout-main-field {
    gap: $dimenS;
  }
}

.checkout-main-form-field,
.ticket-sale-detail-field,
.ticket-sale-detail-resume {
  gap: $dimenXXS;
  p {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: $grey-navbar;
  }
  h3 {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: $grey-dark;
  }
  h4 {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: $grey-blue;
  }
}

.FieldreadOnly {
  cursor: not-allowed;
}

.ticket-sale-detail-resume {
  gap: $dimenS;
}

.purchase-done-main,
.gift-main {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0rem;
  padding: $dimenS;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: $purple-domain;
  gap: $dimenXXXL;
  z-index: 6;
  > * {
    width: 100%;
    max-width: 420px;
  }
  > div {
    gap: $dimenXS;
  }
  svg {
    margin: 0rem;
    width: 128px;
    height: 128px;
  }
  h3 {
    margin: 0rem;
    font-family: $font4;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: $grey-mail-box;
  }
  p {
    margin: 0rem;
    font-family: $font3;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    font-feature-settings: 'calt' off;
    color: $purple-light;
    span {
      font-weight: 700;
    }
  }
  > button {
    width: 100%;
    color: $violet-dark;
    background: $purple;
    border: 0px;
  }
}

.gift-main {
  background: rgba($grey-mail-back, 0.05);
  h3 {
    color: $black-1;
  }
  p {
    color: $grey-dark;
    span {
      color: $black;
    }
  }
  button {
    width: 100%;
    border: 0px;
  }
  button:first-child {
    background: $purple-domain;
    color: $white;
  }
  button:last-child {
    color: $violet-dark;
    background: $purple;
  }
}

@media (max-width: $mediaLG) {
  .home-elvis-main-banner {
    height: 160px;
  }

  .landing-event-banner {
    height: 200px;
  }

  .home-elvis-main-banner button,
  .landing-event-banner button {
    position: absolute;
    top: 22px;
    right: 16px;
  }

  .home-elvis-main-data {
    position: relative;
    top: -84px;
    margin-bottom: -84px;
    padding: $dimenS;
    gap: calc(2 * $dimenS);
    height: 100%;
    min-height: auto;
  }

  .home-elvis-main-data-right,
  .home-elvis-main-data-left {
    width: 100%;
  }

  .home-elvis-main-data-right h3 {
    margin-top: 0px;
    font-size: 20px;
  }

  .home-elvis-main-data-left {
    position: relative;
    top: 0px;
    padding: 0rem;
    gap: $dimenL;
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .home-elvis-main-data-left > svg,
  .home-elvis-main-data-left > img {
    margin: 0rem;
    width: 136px;
    height: 136px;
    padding: $dimenXXS;
    background: $white-2;
    border-radius: 50%;
  }

  .home-elvis-main-data-left h2 {
    font-size: 24px;
  }

  .home-elvis-main-data-left p {
    text-align: center;
  }

  .home-elvis-main-footer {
    padding: $dimenL $dimenS;
  }

  .home-elvis-main-footer-left {
    gap: $dimenXXS;
  }

  .home-elvis-main-footer-left h3 {
    font-size: 22px;
  }

  .home-elvis-main .home-elvis-main-footer-right h4 {
    font-size: 14px;
    text-decoration: underline;
  }

  .landing-event-body {
    padding: $dimenXS $dimenS $dimenXL $dimenS;
    gap: $dimenXL;
  }

  .landing-event-description {
    gap: $dimenS;
  }

  .landing-event-tickets {
    gap: $dimenM;
  }
}

.elipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20ch;
  -webkit-line-clamp: 3;
}

@media (max-width: $mediaXS) {
  .landing-event-metrics-container {
    max-width: none;
  }

  .landing-event-metrics {
    padding: $dimenXL $dimenXS;
    width: 100%;
    min-width: auto;
    border-radius: 20px;
  }

  .detail-event-separator {
    width: 60%;
    min-width: 228px;
    height: 2px;
  }
}
